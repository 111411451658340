import {
  ImageField,
  ImageInput,
  useRecordContext,
} from "react-admin";


import { NameRecord } from "../types";

export const BrandImages = () => {
  const record = useRecordContext<NameRecord>();
  // if (!record) return null;
  if(record){
    record.n_avatar = [{src:record.avatar,  title: 'avatar'}]
    record.n_image = [{src:record.image,  title: 'image'}]
  }

  return (
    <div>
      <ImageInput source="n_avatar">
         <ImageField source="src" />
       </ImageInput>
       <ImageInput source="n_image">
         <ImageField source="src" />
       </ImageInput>
      </div>
   );
 };
