import {
  EditButton,
  List,
  TextField,
  Datagrid,
  useRecordContext,
  TopToolbar,
} from "react-admin";
import { Box, Drawer } from "@mui/material";
import { matchPath, useLocation, useNavigate } from "react-router-dom";
import { Category } from "../types";
import { useCallback } from "react";
import CategoryEdit from "./CategoryEdit";
import CategoryCreate from "./CategoryCreate";
import AddButton from "../comComps/AddButton";
import { hasWrite } from "../authProvider";

const styles = {
  rightCol: { flex: 1, marginLeft: "30px" },
  tableCell: { fontWeight: 500, lineHeight: "1.5rem", padding: "6px 16px" },
};

const defaultSort = { field: "order", order: "ASC" };

const CategoryList = () => {
  const location = useLocation();
  const match = matchPath("/categories/:id", location.pathname);
  const matchCreate = matchPath("/categories/:id/create", location.pathname);
  const matched = !!match || !!matchCreate;
  const navigate = useNavigate();
  const handleClose = useCallback(() => {
    navigate("/categories");
  }, [navigate]);

  return (
    <Box display="flex">
      <List
        perPage={100}
        pagination={false}
        component="div"
        actions={false}
        sx={{
          flexGrow: 1,
          transition: (theme: any) =>
            theme.transitions.create(["all"], {
              duration: theme.transitions.duration.enteringScreen,
            }),
          marginRight: matched ? "400px" : 0,
        }}
      >
        {hasWrite("Categories") && <ListActions />}
        <Datagrid
          rowClick="expand"
          expand={<CategoryGrid1 />}
          isRowSelectable={() => false}
          bulkActionButtons={false}
          sort={defaultSort}
        >
          <TextField source="id" sortable={false} />
          <TextField source="name" sortable={false} />
          <TextField source="complete_name" sortable={false} />
          <TextField source="order" sortable={false} />
          {hasWrite("Categories") && (
            <>
              <EditButton />
              <AddButton label="Create Sub Category" resource="categories" />
            </>
          )}
        </Datagrid>
      </List>
      <Drawer
        variant="persistent"
        open={matched}
        anchor="right"
        onClose={handleClose}
        sx={{ zIndex: 100 }}
      >
        {/* To avoid any errors if the route does not match, we don't render at all the component in this case */}
        {!!match && (
          <CategoryEdit id={(match as any).params.id} onCancel={handleClose} />
        )}
        {!!matchCreate && (
          <CategoryCreate
            id={(matchCreate as any).params.id}
            onCancel={handleClose}
          />
        )}
      </Drawer>
    </Box>
  );
};

const CategoryGrid1 = () => {
  const record = useRecordContext<Category>();
  if (!record || !record.children) return null;
  return (
    <div style={styles.rightCol}>
      <Datagrid
        data={record.children}
        isRowSelectable={() => false}
        bulkActionButtons={false}
        sort={defaultSort}
        rowClick="expand"
        expand={<CategoryGrid2 />}
        sx={{
          "& .MuiTableHead-root": {
            display: "none",
          },
        }}
      >
        <TextField source="name" sortable={false} />
        <TextField source="complete_name" sortable={false} />
        <TextField source="order" sortable={false} />
        {hasWrite("Categories") && (
            <>
              <EditButton />
              <AddButton label="Create Sub Category" resource="categories" />
            </>
        )}
      </Datagrid>
    </div>
  );
};

const CategoryGrid2 = () => {
  const record = useRecordContext<Category>();
  if (!record || !record.children) return null;
  return (
    <div style={styles.rightCol}>
      <Datagrid
        data={record.children}
        bulkActionButtons={false}
        isRowSelectable={() => true}
        sort={defaultSort}
      >
        <TextField source="name" sortable={false} />
        <TextField source="complete_name" sortable={false} />
        <TextField source="order" sortable={false} />
        {hasWrite("Categories") && (
            <EditButton />
          )}
        
      </Datagrid>
    </div>
  );
};

const ListActions = () => (
  <TopToolbar sx={{ minHeight: { sm: 56 } }}>
    <AddButton label="Create Category" resource="categories" />
  </TopToolbar>
);

export default CategoryList;
