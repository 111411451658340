import { useTheme, useMediaQuery, InputAdornment } from "@mui/material";
import LinesEllipsis from "react-lines-ellipsis";
import HTMLEllipsis from 'react-lines-ellipsis/lib/html'

import {
  NumberField,
  useListContext,
  RecordContextProvider,
  EditButton,
} from "react-admin";
import inflection from "inflection";
import {
  Grid,
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Typography,
} from "@mui/material";

const GridList = () => {
  const { isLoading } = useListContext();
  return isLoading ? null : <LoadedGridList />;
};

const useColsForWidth = () => {
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.up("sm"));
  const md = useMediaQuery(theme.breakpoints.up("md"));
  const lg = useMediaQuery(theme.breakpoints.up("lg"));
  const xl = useMediaQuery(theme.breakpoints.up("xl"));
  // there are all dividers of 24, to have full rows on each page
  if (xl) return 6;
  if (lg) return 4;
  if (md) return 2;
  if (sm) return 1;
  return 2;
};

const times = (nbChildren: number, fn: (key: number) => any) =>
  Array.from({ length: nbChildren }, (_, key) => fn(key));
const styles = {
  flex: { display: "flex", justifyContent: "space-between" },
  flexRow: { display: "flex", justifyContent: "space-between" },
};

const LoadedGridList = () => {
  const { data } = useListContext();
  const cols = useColsForWidth();

  if (!data) return null;

  return (
    <Grid container spacing={2} sx={{ marginTop: "1em" }}>
      {data.map((record) => {
        let imgSrc = "#";
        if (record.images && record.images.length > 0) {
          var imgs = record.images.filter((img: any) => img.mainImage > 0);
          if (imgs && imgs.length > 0) {
            imgSrc = imgs[0].imagePath;
          }
        }

        return (
          <RecordContextProvider key={record.id} value={record}>
            <Grid key={record.id} xs={12} sm={6} md={4} lg={3} xl={2} item>
              <Card>
                <CardMedia image={imgSrc} sx={{ height: 140 }} />
                <CardContent sx={{ paddingBottom: "0.5em" }}>
                  <Typography
                    variant="h6"
                    component="div"
                    align="center"
                    sx={{ paddingBottom: "1rem" }}
                  >
                    <LinesEllipsis
                      maxLine="1"
                      ellipsis="..."
                      trimRight
                      basedOn="letters"
                      text={inflection.humanize(record.name?record.name:'')}
                    />
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{ paddingBottom: "1rem", fontWeight: "500", color: "#666" }}
                    component="div"
                  >
                    <LinesEllipsis
                      maxLine="1"
                      ellipsis="..."
                      trimRight
                      basedOn="letters"
                      text={inflection.humanize(record.brand?record.brand:'')}
                    />
                  </Typography>
                  <Typography
                    variant="body1"
                    color={"rgb(159, 159, 159)"}
                    component="div"
                  >
                    <HTMLEllipsis
                      maxLine="3"
                      ellipsis="..."
                      // trimRight
                      basedOn="letters"
                      
                      unsafeHTML={record.description?record.description:''}
                    />
                  </Typography>
                </CardContent>
                <CardActions style={styles.flex}>
                  <div>
                    <Typography variant="subtitle1" style={styles.flex}>
                      <Typography component="span">CAD:</Typography>
                      <NumberField
                        source="price"
                        record={record}
                        color="inherit"
                        options={{
                          style: "currency",
                          currency: "USD",
                        }}
                        sx={{
                          display: "block",
                          fontSize: "1em",
                        }}
                      />
                    </Typography>
                    <Typography variant="subtitle1" style={styles.flex}>
                      <Typography component="span">USD:</Typography>
                      <NumberField
                        source="u_price"
                        record={record}
                        color="inherit"
                        options={{
                          style: "currency",
                          currency: "USD",
                        }}
                        sx={{
                          display: "block",
                          fontSize: "1em",
                        }}
                      />
                    </Typography>
                    </div>
                  <EditButton />
                </CardActions>
              </Card>
            </Grid>
          </RecordContextProvider>
        );
      })}
    </Grid>
  );
};

export default GridList;
