import React, { useMemo, CSSProperties, useEffect } from 'react';
import { useGetList, useRedirect } from 'react-admin';
import { useMediaQuery, Theme } from '@mui/material';
import { subDays, startOfDay } from 'date-fns';
import MonthlyRevenue from './MonthlyRevenue';
import NbNewOrders from './NbNewOrders';
import PendingOrders from './PendingOrders';
import OrderChart from './OrderChart';
import { get_roles, hasRole } from "../authProvider";

import { Order, OrderStatus } from '../types';

interface OrderStats {
    revenue: number;
    nbNewOrders: number;
    pendingOrders: Order[];
}

interface State {
    nbNewOrders?: number;
    pendingOrders?: Order[];
    recentOrders?: Order[];
    revenue?: string;
}

const styles = {
    flex: { display: 'flex' },
    flexColumn: { display: 'flex', flexDirection: 'column' },
    leftCol: { flex: 1, marginRight: '0.5em' },
    rightCol: { flex: 1, marginLeft: '0.5em' },
    singleCol: { marginTop: '1em', marginBottom: '1em' },
};

const od = {
    "address_1": "634 Dearbourne Avenue",
    "address_2": "",
    "city": "King City",
    "country": "Canada",
    "currency": "CAD",
    "email": "alex.ayz@hotmail.co",
    "first_name": "Alex",
    "id": 94,
    "last_name": "Ayzenberg",
    "order_id": 70,
    "phone": "6479881602",
    "postcode": "L7B 1A3",
    "province": "ON",
    "quantity": 2,
    "reference": "ECBZFV-748415",
    "status_id": 2,
    "store": "JessicaLynnMusic",
    "subtotal": 119.98,
    "total": 135.57,
    "updated_at": 1679716173.636285
  };

// 1	unpaid
// 2	paid
// 3	processing
// 4	shipped
// 5	cancelled

const Spacer = () => <span style={{ width: '1em' }} />;
const VerticalSpacer = () => <span style={{ height: '1em' }} />;

const Dashboard = () => {
    // useEffect(() => {
    //     get_roles();
    // }, []);
   
    if(!hasRole('Orders')){
        const redirect = useRedirect();
        redirect('/products');
    }
    const isXSmall = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('sm')
    );
    const isSmall = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('lg')
    );
    const aMonthAgo = useMemo(() => subDays(startOfDay(new Date()), 30), []);

    const { data: orders } = useGetList<Order>('orders', {
        filter: { date_gte: aMonthAgo.toISOString() },
        sort: { field: 'created_at', order: 'DESC' },
        pagination: { page: 1, perPage: 50 },
    });

    const aggregation = useMemo<State>(() => {
        if (!orders) return {};
        const aggregations = orders
            .filter(order => order.status_id !== OrderStatus.cancelled)
            .reduce(
                (stats: OrderStats, order) => {
                    if (order.status_id !== OrderStatus.cancelled) {
                        stats.revenue += order.total;
                        stats.nbNewOrders++;
                    }
                    if (order.status_id === OrderStatus.paid) {
                        stats.pendingOrders.push(order);
                    }
                    return stats;
                },
                {
                    revenue: 0,
                    nbNewOrders: 0,
                    pendingOrders: [],
                }
            );
        return {
            recentOrders: orders,
            revenue: aggregations.revenue.toLocaleString(undefined, {
                style: 'currency',
                currency: 'USD',
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
            }),
            nbNewOrders: aggregations.nbNewOrders,
            pendingOrders: aggregations.pendingOrders,
        };
    }, [orders]);

    const { nbNewOrders, pendingOrders, revenue, recentOrders } = aggregation;
    return isXSmall ? (
        <div>
            <div style={styles.flexColumn as CSSProperties}>
                {/* <Welcome /> */}
                <MonthlyRevenue value={revenue} />
                <VerticalSpacer />
                <NbNewOrders value={nbNewOrders} />
                <VerticalSpacer />
                <PendingOrders orders={pendingOrders} />
            </div>
        </div>
    ) : isSmall ? (
        <div style={styles.flexColumn as CSSProperties}>
            <div style={styles.singleCol}>
                {/* <Welcome /> */}
            </div>
            <div style={styles.flex}>
                <MonthlyRevenue value={revenue} />
                <Spacer />
                <NbNewOrders value={nbNewOrders} />
            </div>
            <div style={styles.singleCol}>
                <OrderChart orders={recentOrders} />
            </div>
            <div style={styles.singleCol}>
                <PendingOrders orders={pendingOrders} />
            </div>
        </div>
    ) : (
        <>
            {/* <Welcome /> */}
            <div style={styles.flex}>
                <div style={styles.leftCol}>
                    <div style={styles.flex}>
                        <MonthlyRevenue value={revenue} />
                        <Spacer />
                        <NbNewOrders value={nbNewOrders} />
                    </div>
                    <div style={styles.singleCol}>
                        <OrderChart orders={recentOrders} />
                    </div>
                    <div style={styles.singleCol}>
                        <PendingOrders orders={pendingOrders} />
                    </div>
                </div>
                {/* <div style={styles.rightCol}>
                    <div style={styles.flex}>
                        <PendingReviews />
                         <Spacer /> 
                        <NewCustomers />
                    </div>
                </div> */}
            </div>
        </>
    );
};

export default Dashboard;
