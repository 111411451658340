import {
  Datagrid,
  List,
  NumberField,
  ReferenceField,
  ReferenceInput,
  TextField,
  useStore,
} from "react-admin";

import { TicketListAside } from "./TicketListAside";
import { StatusField, STATUS_filter } from "./StatusField";
import { DateField } from "./DateField";
import { SubjectField } from "./SubjectField";
import { PriorityField } from "./PriorityField";
import { hide_checkbox } from "../comComps/comStyles";

const filters = [
  <ReferenceInput source="customer_id" reference="customers" alwaysOn />,
  <ReferenceInput source="product_id" reference="products" alwaysOn />,
];

export const TicketList = () => {
  const [statusFilter] = useStore(
    "resources.tickets.list.statusFilter",
    STATUS_filter.all
  );
  return (
    <>
        <List
          sort={{ field: "created_at", order: "DESC" }}
          perPage={25}
          filter={statusFilter}
          aside={<TicketListAside />}
          sx={hide_checkbox}
        >
          <Datagrid
            rowClick="show"
            sx={{
              "& .column-lock": {
                padding: "6px 0",
                "& svg": { verticalAlign: "middle" },
              },
            }}
          >
            <NumberField source="id"/>
            <ReferenceField source="user_id" reference="tickets/users"
              link={false} label="Reporter">
              <TextField source="fullname"/>
            </ReferenceField>
            <DateField source="updated_at" label="Date" textAlign="right" />
            <SubjectField />
            <ReferenceField source="assign_to"
             link={false} reference="tickets/users">
              <TextField source="fullname" />
            </ReferenceField>
            <PriorityField />
            <StatusField />
          </Datagrid>
        </List>
    </>
  );
};
