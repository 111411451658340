import * as React from "react";
import {
  Create,
} from "react-admin";
import BrandEditForm from "./BrandEditForm";
 
const BrandCreate = () => (
  <div>
    <Create title={<span>New product</span>}>
       <BrandEditForm />
    </Create>
  </div>
);
 

export default BrandCreate;
