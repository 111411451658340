import * as React from "react";
import {
  CheckboxGroupInput,
  NumberInput,
  ReferenceInput,
  required,
  SelectInput,
  TextInput,
} from "react-admin";
import { InputAdornment, Grid } from "@mui/material";

export const ProductEditDetails = () => (
  <>
    <Grid item xs={12} sm={12}>
      <TextInput source="name" fullWidth validate={req} />
    </Grid>
    <Grid item xs={12} sm={4}>
      <NumberInput
        label="Influencer Commission"
        source="commissionRate"
        validate={req}
      />
    </Grid>
    <Grid item xs={12} sm={4}>
      <CheckboxGroupInput
        source="purchase_ok"
        choices={[{ id: 1, name: "Is purchase OK" }]}
      />
    </Grid>
    <Grid item xs={12} sm={4}>
      <CheckboxGroupInput
        source="sale_ok"
        choices={[{ id: 1, name: "Is sale OK" }]}
      />
    </Grid>

    <Grid item xs={12} sm={4}>
      <NumberInput
        source="price"
        label="CAD price"
        InputProps={{
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
        }}
        validate={req}
        fullWidth
      />
    </Grid>

    <Grid item xs={12} sm={4}>
      <NumberInput
        source="u_price"
        label="USD price"
        InputProps={{
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
        }}
        validate={req}
        fullWidth
      />
    </Grid>

    <Grid item xs={12} sm={4}>
      <NumberInput
        source="insure_value"
        label="Insured Value"
        InputProps={{
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
        }}
        // validate={req}
        fullWidth
      />
    </Grid>

    <Grid item xs={12} sm={4}>
      <NumberInput source="quantity" validate={req} fullWidth />
    </Grid>

    <Grid item xs={12} sm={4}>
      <TextInput source="default_code" label="SKU" fullWidth />
    </Grid>
    <Grid item xs={12} sm={12}>
      <TextInput source="digit_url"  label="Digital url" fullWidth/>
    </Grid>
  </>
);

const req = [required()];
