export const Rs_Status = [
  {
    id: "Applied",
    name: "Applied",
  },
  {
    id: "Approved",
    name: "Approved",
  },
  {
    id: "Rejected",
    name: "Rejected",
  }
];


