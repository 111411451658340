import * as React from "react";
import {
  required,
  SaveButton,
  TabbedForm,
  Toolbar,
} from "react-admin";
import { RichTextInput } from "ra-input-rich-text";

import UserEditDetail  from "./UserEditDetail";
import UserEditRoles from "./UserEditRoles";
import { hide_checkbox } from "../comComps/comStyles";


const MyToolbar = () => (
  <Toolbar>
    <SaveButton type="button" />
  </Toolbar>
);

const UserEditForm = () => (
      <TabbedForm toolbar={<MyToolbar />}>
        <TabbedForm.Tab
          label="resources.products.tabs.details"
          sx={{ maxWidth: "40em" }}
        >
          <UserEditDetail />
        </TabbedForm.Tab>
        <TabbedForm.Tab
          label="Roles"
          path="roles"
          sx={hide_checkbox}
        >
           <UserEditRoles />
        </TabbedForm.Tab>
        
      </TabbedForm>
);

const req = [required()];

export default UserEditForm;
