import { useRecordContext } from "react-admin";
import { Chip } from "@mui/material";

export const PRIORITY_DICT: any = {0: "Critical", 1: "High", 2: "Medium", 3: "Low"}

export const PRIORITY_choises = Object.keys(PRIORITY_DICT)
  .map(k => ({id: parseInt(k), name: PRIORITY_DICT[k]}));

let priorityFilter: any = {all:{}};
Object.keys(PRIORITY_DICT).forEach(k => priorityFilter[PRIORITY_DICT[k]] = {status: parseInt(k)} );

export const PRIORITY_filter = priorityFilter;


export const PriorityField = (_props: { label?: string }) => {
  const record = useRecordContext();
  if (!record) return null;
  let priority = PRIORITY_DICT[record.priority];
  return (
    <Chip
      label={priority}
      size="small"
      color={
        priority === "Critical"
          ? "error"
          : priority === "High"
          ? "warning"
          : priority === "Medium"
          ? "info"
          : "default"
      }
    />
  );
};

PriorityField.defaultProps = {
  label: "Priority",
};
