import * as React from 'react';
import { Card, CardHeader, List } from '@mui/material';
import { Datagrid, DateField, TextField, useTranslate } from 'react-admin';

import { Order } from '../types';
import { PendingOrder } from './PendingOrder';
import NbItemsField from '../orders/NbItemsField';

interface Props {
    orders?: Order[];
}

const defaultSort = { field: "order", order: "ASC" };

const PendingOrders = (props: Props) => {
    const { orders = [] } = props;
    const translate = useTranslate();
    return (
        <Card sx={{ flex: 1 }}>
            <CardHeader title={translate('pos.dashboard.pending_orders')} />
            <List dense={true}>
            <Datagrid data={orders} sort={defaultSort}>
                <TextField source="store" />
                <TextField source="email" />
                <TextField source="first_name" />
                <TextField source="last_name" />
                <TextField source="reference" />
                <DateField source="created_at" />
                <TextField source="currency" />
                <TextField source="total" />
                <NbItemsField />
            </Datagrid>
                
            </List>
        </Card>
    );
};

export default PendingOrders;
