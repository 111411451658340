import ForumIcon from "@mui/icons-material/Forum";
import { TicketList } from "./TicketList";
import { TicketShow } from "./TicketShow";
import { TicketCreate } from "./TicketCreate";

export default {
  list: TicketList,
  show: TicketShow,
  create: TicketCreate,
  icon: ForumIcon,
};
