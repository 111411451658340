import * as React from "react";
import PropTypes from "prop-types";
import {
  useNotify,
  useRecordContext,
  RaRecord,
  SelectInput,
  SimpleForm,
  Identifier,
  useListContext,
  useUnselectAll,
  useUpdateMany,
  ExportButton,
  useCreate,
} from "react-admin";
import { Rs_Status } from "./rs_status";
import { fetchExcel } from "../dataProvider/fetchJson";

const noSelection: Identifier[] = [];
const mdl = "requestSample";

export const sample_exporter = (ids: any) => {
  fetchExcel(
    mdl + '/export',
    'Requested samples.xlsx',
    {ids: ids},
  );
  // console.dir(ids);
};

const StatusButton = () => {
  const notify = useNotify();
  // const record = useRecordContext<RaRecord>();
  const { selectedIds = noSelection } = useListContext();
  const unselectAll = useUnselectAll(mdl);

  const [updateMany, { isLoading, error }] = useUpdateMany();
 // const [create] = useCreate();
  const onChange = (v: any) => {
    let st = v.target.value;
    if (st === "" || selectedIds.length == 0) {
      return;
    }
    updateMany(
      mdl,
      { ids: selectedIds, data: { status: st } },
      {
        mutationMode: "undoable",
        onSuccess: () => {
          notify("Update status success.", {
            type: "info",
            undoable: true,
          });
          unselectAll();
        },
        onError: () => {
          notify("Update status falied.", {
            type: "error",
          });
        },
      }
    );
  };

  const exporter_selected = (v: any) => {
    sample_exporter(selectedIds);
  };

  return (
    <>
      <SimpleForm
        toolbar={false}
        sx={{
          "& .MuiFormControl-root": {
            margin: "30px 0 0 0",
          },
        }}
      >
        <SelectInput
          source="status"
          choices={Rs_Status}
          emptyText="Select status ..."
          onChange={onChange}
        />
      </SimpleForm>
      <ExportButton exporter={exporter_selected} />
    </>
  );
};

StatusButton.propTypes = {
  record: PropTypes.any,
};

export default StatusButton;
