import * as React from 'react';
import { FunctionField } from 'react-admin';
import { Order } from '../types';

const render = (record?: Order) => {
    const initialValue = 0;
    let q = 0;
    
    if(record && record.basket && record.basket.length){
        q = record.basket.reduce(
            (q, currentValue) => q + currentValue.quantity,
            initialValue
          );
    }
    return q
};

const NbItemsField = () => <FunctionField<Order> render={render} />;

NbItemsField.defaultProps = {
    label: 'Quantity',
    textAlign: 'right',
};

export default NbItemsField;
