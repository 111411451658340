import { fetchUtils, HttpError, Options } from "react-admin";

export const fetchJson = (url: string, options: Options = {}) => {
  const requestHeaders = fetchUtils.createHeadersFromOptions(options);

  return fetch(url, { ...options, headers: requestHeaders })
    .then((response) =>
      response.text().then((text) => ({
        status: response.status,
        statusText: response.statusText,
        headers: response.headers,
        body: text,
      }))
    )
    .then(({ status, statusText, headers, body }) => {
      let json;
      try {
        json = JSON.parse(body);
      } catch (e) {
        // not json, no big deal
      }
      if (json && json.code && json.code !== 200) {
        return Promise.reject(new Error(json.message));
      }
      if (status < 200 || status >= 300) {
        return Promise.reject(
          new HttpError((json && json.message) || statusText, status, json)
        );
      }
      return Promise.resolve({ status, headers, body, json });
    });
};

export const fetchExcel = (url: string, fileName: string = "", data: any = {}) => {
  const token = localStorage.getItem('ba_token');
  let url_ =  `${process.env.API_URL}/napi/${url}`
  fetch(url_, {
    body:  JSON.stringify(data),
    method: "POST",
    headers: {
      "Authorization": `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.blob())
    .then((blob) => {
      // Create blob link to download
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();
      window.URL.revokeObjectURL( url );
      // Clean up and remove the link
      link?.parentNode?.removeChild(link);
    });
};
