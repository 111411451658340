import {
  Show,
  TextField,
  ReferenceManyField,
  DateField,
  SimpleShowLayout,
  useRecordContext,
  useUpdate,
  SelectInput,
  required,
  ReferenceInput,
  Form,
} from "react-admin";
import { Box } from "@mui/material";

import { MessageList } from "./MessageList";
import { STATUS_choises } from "./StatusField";
import { PRIORITY_choises } from "./PriorityField";

export const TicketShow = () => {
  return (
    <Show aside={<ShowAside />}>
      <SimpleShowLayout>
        <TextField source="subject" label="" variant="h5" sx={{ ml: "12px" }} />
        <ReferenceManyField
          label={false}
          reference="tickets/messages"
          target="ticket_id"
          sort={{ field: "timestamp", order: "ASC" }}
        >
          <MessageList />
        </ReferenceManyField>
      </SimpleShowLayout>
    </Show>
  );
};

const ShowAside = () => {
  const record = useRecordContext();
  if (!record) return <Box minWidth={200} flexShrink={0} />;
  const [update, { isLoading: isCreating }] = useUpdate();
  const onChange = (values: any) => {
    update(
      "tickets",
      { id: record.id, data: values.target },
      {
        onSuccess: () => {},
        onError: () => {},
      }
    );
  };

  return (
    <SimpleShowLayout
      sx={{
        minWidth: 200,
        flexShrink: 0,
        "& form": {
          display: "inline-flex",
          flexDirection: "column",
        },
      }}
    >
      {/* <StatusField label="Status" /> */}
      <DateField source="updated_at" showTime />
      <Form record={record}>
        <SelectInput
          source="priority"
          choices={PRIORITY_choises}
          validate={required()}
          onChange={onChange}
        />
        <SelectInput
          source="status"
          choices={STATUS_choises}
          validate={required()}
          onChange={onChange}
        />
        <ReferenceInput source="assign_to" reference="tickets/users"
        >
          <SelectInput optionText="fullname" onChange={onChange} />
        </ReferenceInput>
      </Form>
    </SimpleShowLayout>
  );
};
