import * as React from 'react';
import {
    List,
    Datagrid,
    TextField,
    DateField,
    ReferenceField,
    NumberField,
    DateInput,
    SelectField,
    SelectInput,
    RowClickFunction,
    RaRecord,
    Identifier,
    ExportButton,
    Exporter,
    useListContext,
    TextInput,
    useGetList,
    RichTextField,
} from 'react-admin';


import { Rs_Status } from './rs_status';
import StatusButton, { sample_exporter } from './StatusButton';
import ProductLinkField from './ProductLinkField';

const listFilters = [
    <DateInput source="date_gte" label="Date from" alwaysOn />,
    <DateInput source="date_lte" label="Date to"  alwaysOn />,
    <SelectInput label="Status" source="status" alwaysOn choices={Rs_Status}/>,
    // <TextInput source="store" alwaysOn />,
];

 
const ActionButtons = () => (
    <div className='rq_toolbar'>
        <StatusButton />
    </div>
);

const exporter = (samples: any) => {
    // console.dir(samples);
    const selectedIds = samples.map(( sample : RaRecord ) => sample.id);
    sample_exporter(selectedIds);
};
 
const RequestList = () => {
    return (
        <List
            filters={listFilters}
            perPage={25}
            resource='requestSample'
            sort={{ field: 'created_at', order: 'desc' }}
            exporter={exporter}
        >
            <Datagrid
                // rowClick='edit'
                bulkActionButtons={<ActionButtons />} 
                sx={{
                    '& .brand': {
                       fontWeight: 700,
                       marginRight: '20px'
                    },
                    '& .rq_toolbar': {
                        display: 'flex'
                     },
                }}
            >
                <TextField source="id" />
                <DateField source="created_at" label="Date"/>
                <TextField source="store" />
                <TextField source="full_name" label="Name"/>
                <TextField source="email" />
                <ReferenceField source="store_id" reference="stores/socials" label="Social Handles">
                    <RichTextField source="socials" />
                </ReferenceField>
                <TextField source="size" />
                <TextField source="color" />
                <SelectField source="status" choices={Rs_Status} />
                <ReferenceField source="product_id" reference="products/ref">
                    <ProductLinkField />
                </ReferenceField>
            </Datagrid>
        </List>
    );
};

export default RequestList;
