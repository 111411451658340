import * as React from "react";
import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import {
  DateInput,
  Edit,
  ImageField,
  ImageInput,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
  useGetList,
  useRecordContext,
} from "react-admin";
import { NameRecord } from "../types";

const Images = () => {
  const record = useRecordContext<NameRecord>();
  if (record) {
    record.image = [{ src: record.image, title: "Image" }];
  }
  
  return (
    <ImageInput source="image">
      <ImageField source="src" />
    </ImageInput>
  );
};

const PartnerShipEditForm = () => {
  // const { data } = useGetList("brands/id_names", {
  //   pagination: { page: 1, perPage: 1000 },
  // });

  // let chs: any = data? data: [];
  // if(data){
  //   chs = data.map(v =>{
  //     return {id: v.name, name: v.name};
  //   })
  // } ;
  return (
      <SimpleForm
        sx={{
          "& .MuiTypography-root": {
            margin: "0.6rem",
            fontWeight: "500",
            fontSize: "1rem",
          },
        }}
      >
        <Grid container columnSpacing={2}>
          <Grid item xs={12} sm={12}>
            <Images />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput source="title" fullWidth validate={req} />
          </Grid>
          <Grid item xs={12} sm={6}>
            {/* <SelectInput
              source="brand"
              label="Brand"
              choices={chs}
              optionValue="name"
              fullWidth
              validate={req}
            /> */}
            <TextInput
              source="brand"
              fullWidth
              validate={req}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput
              source="description"
              label="Program description"
              fullWidth
              validate={req}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput
              source="qualifications"
              label="Creator qualifications"
              fullWidth
              validate={req}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SelectInput
              validate={req}
              source="status"
              label="Status"
              choices={PS_Status}
            />
          </Grid>
          <Grid item xs={6} sm={4}>
            <DateInput source="updated_at" label="Date" disabled />
          </Grid>
        </Grid>
      </SimpleForm>
  );
};
const req = [required()];
export const PS_Status = [
  {
    id: "Enable",
    name: "Enable",
  },
  {
    id: "Disable",
    name: "Disable",
  },
];

export default PartnerShipEditForm;
