import * as React from 'react';
import { Link, FieldProps, useRecordContext, RaRecord } from 'react-admin';

import { memo } from 'react';
import { Typography } from '@mui/material';


export interface Props extends FieldProps<RaRecord> {
  reference: string;
  keyId: string;
}
const LinkField = (props: Props) => {
    const record = useRecordContext<RaRecord>();
    if (!record) {
        return null;
    }
    // console.dir(record)
    return (
        <Link to={`/${props.reference}/${record[props.keyId]}`}>
          <Typography
            variant="body2"
            display="flex"
            flexWrap="nowrap"
            alignItems="center"
            component="div"
        >
          {props.resource? record[props.resource]: ''}
        </Typography>
        </Link>
    );
};

export default memo<Props>(LinkField);

