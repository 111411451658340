import * as React from "react";
import { useState } from "react";
import Box from "@mui/material/Box";
import LabelIcon from "@mui/icons-material/Label";

import {
  useTranslate,
  DashboardMenuItem,
  MenuItemLink,
  MenuProps,
  useSidebarState,
} from "react-admin";

import visitors from "../visitors";
import orders from "../orders";
import coupon from "../coupon";
import invoices from "../invoices";
import products from "../products";
import categories from "../categories";
import satistics from "../satistics";
import stores from "../stores";
import brands from "../brands";
import users from "../users";
import requestSample from "../requestSample";
import partnershipApply from "../partnershipApply";
import tickets from "../tickets";
import partnership from "../partnership";
import SubMenu from "./SubMenu";
import StoreProducts from '../StoreProducts';
import { get_roles, hasRole } from "../authProvider";

type MenuName = "menuCatalog" | "menuSales" | "menuCustomers";

const Menu = ({ dense = false }: MenuProps) => {

  React.useEffect(() => {
    get_roles();
  }, []);

  const [state, setState] = useState({
    menuCatalog: true,
    menuSales: true,
    menuCustomers: true,
  });
  const translate = useTranslate();
  const [open] = useSidebarState();

  const handleToggle = (menu: MenuName) => {
    setState((state) => ({ ...state, [menu]: !state[menu] }));
  };

  return (
    <Box
      sx={{
        width: open ? 200 : 50,
        marginTop: 1,
        marginBottom: 1,
        transition: (theme) =>
          theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
      }}
    >
      {hasRole("Orders") && <DashboardMenuItem />}
      {hasRole("Tracks") && (
        <MenuItemLink
          to="/satistics"
          state={{ _scrollToTop: true }}
          primaryText={"Tracks"}
          leftIcon={<satistics.icon />}
          dense={dense}
        />
      )}
      {hasRole("Orders") && <MenuItemLink
            to="/orders"
            state={{ _scrollToTop: true }}
            primaryText={"Orders"}
            leftIcon={<orders.icon />}
            dense={dense}
          />}
      {hasRole("Orders") && <MenuItemLink
            to="/coupon"
            state={{ _scrollToTop: true }}
            primaryText={"Coupon"}
            leftIcon={<coupon.icon />}
            dense={dense}
          />}
      {hasRole("Stores") && <MenuItemLink
            to="/stores"
            state={{ _scrollToTop: true }}
            primaryText={"Stores"}
            leftIcon={<stores.icon />}
            dense={dense}
          />}
    
      {hasRole("Brands") &&  <MenuItemLink
        to="/brands"
        state={{ _scrollToTop: true }}
        primaryText={"Brands"}
        leftIcon={<brands.icon />}
        dense={dense}
      />}
      {hasRole("Products") && <MenuItemLink
        to="/products"
        state={{ _scrollToTop: true }}
        primaryText={translate(`resources.products.name`, {
          smart_count: 2,
        })}
        leftIcon={<products.icon />}
        dense={dense}
      />}
      {hasRole("Categories") &&  <MenuItemLink
        to="/categories"
        state={{ _scrollToTop: true }}
        primaryText={translate(`resources.categories.name`, {
          smart_count: 2,
        })}
        leftIcon={<categories.icon />}
        dense={dense}
      />}
       {hasRole("Users") && <MenuItemLink
            to="/users"
            state={{ _scrollToTop: true }}
            primaryText={"Users"}
            leftIcon={<users.icon />}
            dense={dense}
          />}
       {hasRole("Stores") && <MenuItemLink
            to="/requestSample"
            state={{ _scrollToTop: true }}
            primaryText={"Gifted collab"}
            leftIcon={<requestSample.icon />}
            dense={dense}
          />}
       {hasRole("Stores") && <MenuItemLink
            to="/StoreProducts"
            state={{ _scrollToTop: true }}
            primaryText={"Store Products"}
            leftIcon={<StoreProducts.icon />}
            dense={dense}
          />}
       {hasRole("Stores") && <MenuItemLink
            to="/tickets"
            state={{ _scrollToTop: true }}
            primaryText={"Tickets"}
            leftIcon={<tickets.icon />}
            dense={dense}
          />}
        {hasRole("Stores") && <MenuItemLink
            to="/partnership"
            state={{ _scrollToTop: true }}
            primaryText={"Partnership"}
            leftIcon={<partnership.icon />}
            dense={dense}
          />}
        {hasRole("Stores") && <MenuItemLink
            to="/partnershipApply"
            state={{ _scrollToTop: true }}
            primaryText={"Partnership Applied"}
            leftIcon={<partnership.icon />}
            dense={dense}
          />}
      {/* <SubMenu
                handleToggle={() => handleToggle('menuSales')}
                isOpen={state.menuSales}
                name="pos.menu.sales"
                icon={<orders.icon />}
                dense={dense}
            >
                <MenuItemLink
                    to="/commands"
                    state={{ _scrollToTop: true }}
                    primaryText={translate(`resources.commands.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<orders.icon />}
                    dense={dense}
                />
                <MenuItemLink
                    to="/invoices"
                    state={{ _scrollToTop: true }}
                    primaryText={translate(`resources.invoices.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<invoices.icon />}
                    dense={dense}
                />
            </SubMenu> */}
      {/* <SubMenu
                handleToggle={() => handleToggle('menuCatalog')}
                isOpen={state.menuCatalog}
                name="pos.menu.catalog"
                icon={<products.icon />}
                dense={dense}
            > */}
      
     
      {/* </SubMenu> */}
      {/* <SubMenu
                handleToggle={() => handleToggle('menuCustomers')}
                isOpen={state.menuCustomers}
                name="pos.menu.customers"
                icon={<visitors.icon />}
                dense={dense}
            >
                <MenuItemLink
                    to="/customers"
                    state={{ _scrollToTop: true }}
                    primaryText={translate(`resources.customers.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<visitors.icon />}
                    dense={dense}
                />
                <MenuItemLink
                    to="/segments"
                    state={{ _scrollToTop: true }}
                    primaryText={translate(`resources.segments.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<LabelIcon />}
                    dense={dense}
                />
            </SubMenu> */}
      {/* <MenuItemLink
                to="/reviews"
                state={{ _scrollToTop: true }}
                primaryText={translate(`resources.reviews.name`, {
                    smart_count: 2,
                })}
                leftIcon={<reviews.icon />}
                dense={dense}
            /> */}
      {/* <MenuItemLink
                to="/users"
                state={{ _scrollToTop: true }}
                primaryText={translate(`resources.reviews.name`, {
                    smart_count: 2,
                })}
                leftIcon={<reviews.icon />}
                dense={dense}
            /> */}
    </Box>
  );
};

export default Menu;
