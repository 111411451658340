import * as React from "react";
import { Link, FieldProps, useRecordContext, RaRecord } from "react-admin";

import { Typography } from "@mui/material";

const ProductLinkField = (props: FieldProps<RaRecord>) => {
  const record = useRecordContext<RaRecord>();
  if (!record) {
    return null;
  }
  return (
    <Link to={`/products/${record.id}`}>
      <Typography
        variant="body2"
        display="flex"
        flexWrap="nowrap"
        alignItems="center"
        component="div"
      >
        <span className="brand"> {record.brand}</span><span>{record.name}</span>
      </Typography>
    </Link>
  );
};

ProductLinkField.defaultProps = {
  source: "product_id",
};

export default ProductLinkField;
