import DomainIcon from '@mui/icons-material/Domain';

import SatisticsList from './SatisticsList';
import VisitorCreate from './VisitorCreate';
import VisitorEdit from './VisitorEdit';

const resource = {
    list: SatisticsList,
    // create: VisitorCreate,
   // edit: VisitorEdit,
    icon: DomainIcon,
};

export default resource;
