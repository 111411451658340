import BusinessIcon from '@mui/icons-material/Business';
import BrandList from './BrandList';
import BrandEdit from './BrandEdit';
import BrandCreate from './BrandCreate';


export default {
    list: BrandList,
    edit: BrandEdit,
    create: BrandCreate,
    icon: BusinessIcon,
};
