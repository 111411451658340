import * as React from "react";
import {
  Create,
  TabbedForm,
  SimpleForm,
  CheckboxGroupInput,
  NumberInput,
  ReferenceInput,
  required,
  SelectInput,
  TextInput,
  useListContext,
  useGetList,
} from "react-admin";

import { InputAdornment, Grid } from "@mui/material";
import { ProductEditDetails } from "./ProductEditDetails";

const ProductCreate = () => {
  const { data } = useGetList("brands/id_names", {
    pagination: { page: 1, perPage: 1000 },
  });

  let chs = data ? data : [];
  return (
    <Create>
      <SimpleForm>
        <Grid container columnSpacing={2}>
          <Grid item xs={12} sm={12}>
            <SelectInput
              source="merchant"
              label="Brand"
              choices={chs}
              fullWidth
              validate={req}
            />
          </Grid>
          <ProductEditDetails />
        </Grid>
      </SimpleForm>
    </Create>
  );
};

const req = [required()];

export default ProductCreate;
