import { useRecordContext } from "react-admin";
import { Chip } from "@mui/material";

export const STATUS_DICT: any = {0: "open", 1: "reopen", 2: "processing", 3: "fixed", 5: "closed"}

export const STATUS_choises = Object.keys(STATUS_DICT)
  .map(k => ({id: parseInt(k), name: STATUS_DICT[k]}));

let statusFilter: any = {all:{}};
Object.keys(STATUS_DICT).forEach(k => statusFilter[STATUS_DICT[k]] = {status: parseInt(k)} );

export const STATUS_filter = statusFilter;

export const StatusField = (_props: { label?: string }) => {
  const record = useRecordContext();
  if (!record) return null;
  let status = STATUS_DICT[record.status];
  return (
    <Chip
      label={status}
      size="small"
      color={
        status === "open" || status === "reopen"
          ? "primary"
          : status === "processing"
          ? "secondary"
          : "default"
      }
    />
  );
};

StatusField.defaultProps = {
  label: "Status",
};

