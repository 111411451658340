import * as React from 'react';
import {
    List,
    Datagrid,
    TextField,
    DateField,
    ReferenceField,
    NumberField,
    DateInput,
} from 'react-admin';

import { hide_checkbox } from '../comComps/comStyles';
import LinkField from '../comComps/LinkField';
import ProductLinkField from '../requestSample/ProductLinkField';

const listFilters = [
    <DateInput source="date_gte" alwaysOn />,
    <DateInput source="date_lte" alwaysOn />,
];

const StoreProducts = () => {
    return (
        <List
            // filters={listFilters}
            
            perPage={25}
            resource='stores/products'
            sort={{ field: 'created_at', order: 'desc' }}
        >
            <Datagrid
                rowClick="expand"
                isRowSelectable={() => false}
               // expand={false}
                sx={{
                    '& .column-customer_id': {
                        display: { xs: 'none', md: 'table-cell' },
                    },
                    '& .column-total_ex_taxes': {
                        display: { xs: 'none', md: 'table-cell' },
                    },
                    '& .column-delivery_fees': {
                        display: { xs: 'none', md: 'table-cell' },
                    },
                    '& .column-taxes': {
                        display: { xs: 'none', md: 'table-cell' },
                    },...hide_checkbox
                }}
            >
                <NumberField source="id" />
                <DateField source="created_at" label="Added at" />
                <TextField source="store_name" label="Store" />
                <TextField source="product_brand"/>
                <LinkField
                    reference="products"
                    label="Product name"
                    keyId="goods_id"
                    resource="product_name"
                />
             
            </Datagrid>
        </List>
    );
};

export default StoreProducts;
