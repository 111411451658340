import { ImageList, ImageListItem, Modal, Backdrop, Fade } from "@mui/material";
import React, { useState } from "react";
import AttachmentIcon from "@mui/icons-material/Attachment";
const image_exts = ["png", "jpg", "jpeg", "gif"];
function is_image(url: string) {
  let ext = url.split(/[#?]/)[0].split(".").pop()?.trim();
  ext = ext ? ext : "";
  return image_exts.includes(ext);
}

export const ImagesField = (_props: { label: string; image: string }) => {
  if (!_props.image || _props.image == "") return null;
  let imgs = _props.image.split("|");
  if (!imgs || imgs.length < 1) return null;

  const [open, setOpen] = useState(false);
  const [image, setImage] = useState("false");

  const handleClose = () => {
    setOpen(false);
  };

  const handleImage = (value: string) => {
    setImage(value);
    setOpen(true);
    console.log(image);
  };

  return (
    <>
      <ImageList
        sx={{
          // width: 500, height: 500
          "& img": { maxWidth: "120px" },
        }}
        cols={3}
        rowHeight={120}
      >
        {imgs.map((img) => {
          let attach = is_image(img) ? (
            <img
              onClick={(e) => handleImage(img)}
              src={`${img}?w=
            164&h=164&fit=crop&auto=format`}
              srcSet={`${img}?w=
            164&h=164&fit=crop&auto=format&dpr=2 2x`}
            />
          ) : (
            <a href={`${img}`}>
              <AttachmentIcon />
            </a>
          );
          return <ImageListItem key={img}>{attach}</ImageListItem>;
        })}
      </ImageList>
      <Modal
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          "&:hover": {
            backgroundcolor: "red",
          },
          "& img": {
            outline: "none",
          },
        }}
        open={open}
        onClose={handleClose}
        closeAfterTransition
      >
        <Fade in={open} timeout={500}>
          <img
            src={image}
            alt=""
            style={{ maxHeight: "100%", maxWidth: "100%" }}
          />
        </Fade>
      </Modal>
    </>
  );
};

ImagesField.defaultProps = {
  label: "Screenshots",
  image: "",
};
