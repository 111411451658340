import * as React from "react";
import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import {
  DateInput,
  Edit,
  ImageField,
  ImageInput,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
  useRecordContext,
} from "react-admin";
import { NameRecord } from "../types";
import PartnerShipEditForm from "./PartnerShipEditForm";

const Images = () => {
  const record = useRecordContext<NameRecord>();
  if (!record) {
    return null;
  }
  record.image = [{ src: record.image, title: "Image" }];
  return (
    <ImageInput source="image">
      <ImageField source="src" />
    </ImageInput>
  );
};

const PartnerShipEdit = () => {
  return (
    <Edit
      title=""
      component="div"
      sx={{
        "& .ra-delete-button": {
          display: "none",
        },
      }}
    >
     <PartnerShipEditForm />
    </Edit>
  );
};

export default PartnerShipEdit;
