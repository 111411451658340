import * as React from 'react';
import {
    List,
    Datagrid,
    TextField,
    DateField,
    ReferenceField,
    NumberField,
    DateInput,
} from 'react-admin';

import { hide_checkbox } from '../comComps/comStyles';

const listFilters = [
    <DateInput source="date_gte" alwaysOn />,
    <DateInput source="date_lte" alwaysOn />,
];

const CouponList = () => {
    return (
        <List
            // filters={listFilters}
            
            perPage={25}
            resource='orders/coupons'
            sort={{ field: 'date', order: 'desc' }}
        >
            <Datagrid
                rowClick="expand"
                isRowSelectable={() => false}
               // expand={false}
                sx={{
                    '& .column-customer_id': {
                        display: { xs: 'none', md: 'table-cell' },
                    },
                    '& .column-total_ex_taxes': {
                        display: { xs: 'none', md: 'table-cell' },
                    },
                    '& .column-delivery_fees': {
                        display: { xs: 'none', md: 'table-cell' },
                    },
                    '& .column-taxes': {
                        display: { xs: 'none', md: 'table-cell' },
                    },...hide_checkbox
                }}
            >
                <ReferenceField source="id" reference="orders" label="Order number">
                    <TextField source="reference" />
                </ReferenceField>
                <DateField source="created_at" label="Order at" />
                <ReferenceField source="influencer_id" reference="stores">
                    <TextField source="username" />
                </ReferenceField>
                <TextField source="promotion_code" />
                <TextField source="currency" />
                <NumberField source="amount_off" options={{
                    style: "currency",
                    currency: "USD",
                    }}/>
                <NumberField source="percent_off" options={{ style: 'percent' }}/>
                <NumberField source="total" label="Sub Total" options={{
                    style: "currency",
                    currency: "USD",
                    }}/>
            </Datagrid>
        </List>
    );
};

export default CouponList;
