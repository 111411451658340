import * as React from 'react';
import {
    List,
    Datagrid,
    TextField,
    DateField,
    ReferenceField,
    NumberField,
    DateInput,
    BooleanField,
    EditButton,
} from 'react-admin';

import { hide_checkbox } from '../comComps/comStyles';

const UserList = () => {
    return (
        <List
            // filters={listFilters}
            perPage={25}
            sort={{ field: 'create_date', order: 'desc' }}
            sx={hide_checkbox}
        >
            <Datagrid rowClick="edit">
                <TextField source="id" />
                <TextField source="fullname" />
                <TextField source="companyname" />
                <TextField source="userrole" label="Role" />
                <TextField source="email" />
                <TextField source="address1" />
                <TextField source="city"/>
                <TextField source="province" />
                <TextField source="country" />
                <TextField source="postcode" />
                <TextField source="tel" />
                <BooleanField source="lockoutenabled" label="Locked"/>
                <EditButton />
               
            </Datagrid>
        </List>
    );
};

export default UserList;
