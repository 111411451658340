import * as React from "react";
import {
  Edit,
  useRecordContext,
} from "react-admin";
import { NameRecord } from "../types";
import BrandEditForm from "./BrandEditForm";

const BrandTitle = () => {
  const record = useRecordContext<NameRecord>();
  return record ? <span>Brand: {record.name}</span> : null;
};

const BrandEdit = () => (
  <div>
    <Edit title={<BrandTitle />}>
       <BrandEditForm />
    </Edit>
  </div>
);
 

export default BrandEdit;
