import { AuthProvider, fetchUtils } from 'react-admin';

const api_base = process.env.API_URL + '/napi/users'

const authProvider: AuthProvider = {
    login: async ({ username, password }) =>  {
        const request = new Request(api_base + '/login', {
            method: 'POST',
            body: JSON.stringify({ email: username, password }),
            headers: new Headers({ 'Content-Type': 'application/json' }),
        });
        del_roles();
        try {
            const response = await fetch(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.statusText);
            }
            const { data, code, message } = await response.json();
            set_roles(data, code, message);
        } catch(e) {
            throw e;
        }
    },
    logout: () => {
      clean_tokens();
      return Promise.resolve();
    },
    checkError: () => { 
        return Promise.resolve() },
    checkAuth: () => {
        return  localStorage.getItem('ba_token') ? Promise.resolve() : Promise.reject();
    },
    getPermissions: () => Promise.reject('Unknown method'),
    getIdentity: () =>{
        const user_info_str = localStorage.getItem('ba_user');
        if(user_info_str && user_info_str !== null){
            const user_info = JSON.parse(user_info_str);
            return Promise.resolve({
                id: user_info.username,
                fullName: user_info.username,
            });
        }
        return Promise.reject();
    },
};

export const get_roles = async () => {
  const token = localStorage.getItem("ba_token");
  let user_ = localStorage.getItem("ba_user");
  if (!token || !user_) {
    return;
  }
  let user = JSON.parse(user_);
  const request = new Request(api_base + "/role", {
    method: "GET",
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    }),
  });
  try {
    const response = await fetch(request);
    const { data, code, message } = await response.json();
    set_roles(data, code, message);
  } catch (e) {}    
};

const del_roles = () => {
  let user_ = localStorage.getItem("ba_user");
  if (!user_) {
    return;
  }
  let user = JSON.parse(user_);
  user["roles"] = [];
  localStorage.setItem("ba_user", JSON.stringify(user));
};

const clean_tokens = () =>{
  localStorage.removeItem('ba_token');
  localStorage.removeItem('ba_user');
}
const set_roles = (data: any, code: any, message: any) => {
  if (code != 200) {
    clean_tokens();
    throw new Error(message);
  }
  const { token } = data
  delete data['token']
  // store the token in local storage
  localStorage.setItem('ba_token', token);
  localStorage.setItem('ba_user',JSON.stringify(data));
};

export function createOptionsFromToken() {
    const token = localStorage.getItem('ba_token');
    if (!token) {
      return {};
    }
    return {
      user: {
        authenticated: true,
        token: 'Token ' + token,
      },
    };
  }
  
  export function fetchJsonWithAuthToken(url: string, options: object) {
    return fetchUtils.fetchJson(
      url,
      Object.assign(createOptionsFromToken(), options)
    );
  }
  
  export function is_admin() {
    const user_info_str = localStorage.getItem('ba_user');
        if(user_info_str && user_info_str !== null){
            const user_info = JSON.parse(user_info_str);
            return user_info.role == 'Admin';
        }
    return false;
  }
    
  export function hasRole(role: string, p = 'read') {
    if (!role) return false;
    let idx = p == 'read'? 0: 1;
    const user_info_str = localStorage.getItem('ba_user');
        if(user_info_str && user_info_str !== null){
            const user_info = JSON.parse(user_info_str);
            if (user_info.roles && user_info.roles[role] && user_info.roles[role].length > 1){
              return user_info.roles[role][idx];
            }
        }
    return false;
  }

  export function hasWrite(role: string){
    return hasRole(role, 'write');
  }

export default authProvider;
