import * as React from "react";
import {
  BooleanField,
  Datagrid,
  DateField,
  DateInput,
  List,
  NullableBooleanInput,
  NumberField,
  SearchInput,
  TextField,
  useGetList,
} from "react-admin";
import { useMediaQuery, Theme } from "@mui/material";

import SegmentsField from "./SegmentsField";
import SegmentInput from "./SegmentInput";
import CustomerLinkField from "./CustomerLinkField";
import ColoredNumberField from "./ColoredNumberField";
import MobileGrid from "./MobileGrid";
import VisitorListAside from "./VisitorListAside";
import { TrackView } from "../types";

const SatisticsList = () => {
  //const { data: tracks } = useGetList<TrackView>("satistics/track_view", {});
  const isXsmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("sm")
  );
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("md"));
  return (
    <List
      resource={"satistics/track_view"}
      perPage={20}
      sx={{
        "& .MuiCheckbox-root": {
          display: "none",
        },
      }}
    >
      {/* {isXsmall ? (
        <MobileGrid />
      ) : ( */}
        <Datagrid
          optimized
          isRowSelectable={() => false}
          // rowClick="edit"
          //  data={tracks}
          sx={{
            "& .column-groups": {
              md: { display: "none" },
              lg: { display: "table-cell" },
            },
          }}
        >
          <TextField source="date" sortable={false} />
          <NumberField source="InfluencerStore" sortable={false} />
          <NumberField source="ManuProductLanding" sortable={false} />
          <NumberField source="ManuProduct" sortable={false} />
          <NumberField source="AddProducts" sortable={false} />
          <NumberField source="ProductList" sortable={false} />
          <NumberField source="ProductPage" sortable={false} />
          <NumberField source="CreateStore" sortable={false} />
          <NumberField source="CreateStore1" sortable={false} />
          <NumberField source="CreateStore2" sortable={false} />
          <NumberField source="CreateStore3" sortable={false} />
          <NumberField source="CreateStore4" sortable={false} />
          <NumberField source="CreateStore5" sortable={false} />
          <NumberField source="SocialConnect" sortable={false} />
          <NumberField source="SocialConnected" sortable={false} />
          <NumberField source="LogIn" sortable={false} />
          <NumberField source="SignUp" sortable={false} />
          <NumberField source="checkout_cart" sortable={false} />
          <NumberField source="checkout_buy" sortable={false} />
        
        </Datagrid>
      {/* )} */}
    </List>
  );
};

export default SatisticsList;
