import { RaRecord, Identifier } from 'react-admin';

export type ThemeName = 'light' | 'dark';

export interface Category extends RaRecord {
    name: string;
    parent_id?: number;
    parent_path: string;
    complete_name: string;
    children: Category[];
}

export interface Product extends RaRecord {
    category_id: Identifier;
    description: string;
    height: number;
    image: string;
    images: any[];
    list_price: number;
    price: string;
    stock: number;
    thumbnail: string;
    width: number;
    categories?: any[];
}


export interface NameRecord extends RaRecord {
    name: string;
    fullname: string
}


export interface ProductImage extends RaRecord {
    image: string;
    main_media: number
}



export interface Customer extends RaRecord {
    first_name: string;
    last_name: string;
    address: string;
    stateAbbr: string;
    city: string;
    zipcode: string;
    avatar: string;
    birthday: string;
    first_seen: string;
    last_seen: string;
    has_ordered: boolean;
    latest_purchase: string;
    has_newsletter: boolean;
    groups: string[];
    nb_commands: number;
    total_spent: number;
}

export interface TrackView extends RaRecord {
    InfluencerStore?: number;
    ManuProductLanding?: number;
    ManuProduct?: number;
    AddProducts?: number;
    ProductList?: number;
    ProductPage?: number;
    CreateStore?: number;
    CreateStore1?: number;
    CreateStore2?: number;
    CreateStore4?: number;
    CreateStore3?: number;
    CreateStore5?: number;
    SocialConnect?: number;
    SocialConnected?: number;
    MyAccount?: number;
    EditStore?: number;
    EditPosts?: number;
    EditProducts?: number;
    EditProfile?: number;
    LogIn?: number;
    Menu?: number;
    Dashboard?: number;
    Search?: number;   
    SignUp?: number;
}

export type OrderStatus = 'paid' | 'shipped' | 'cancelled';

export interface Order extends RaRecord {
    status: OrderStatus;
    basket: BasketItem[];
    date: Date;
    total: number;
}

export const OrderStatus = {
    unpaid: 1,
    paid: 2,
    processing: 3,
    shipped: 4,
    cancelled: 5,
}

export interface BasketItem {
    product_id: Identifier;
    quantity: number;
}

export interface Invoice extends RaRecord {}

export type ReviewStatus = 'accepted' | 'pending' | 'rejected';

export interface Review extends RaRecord {
    date: Date;
    status: ReviewStatus;
    customer_id: Identifier;
    product_id: Identifier;
}

declare global {
    interface Window {
        restServer: any;
    }
}
