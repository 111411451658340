import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  DateField,
  ReferenceField,
  NumberField,
  SelectField,
  BooleanField,
  EditButton,
} from "react-admin";

import InvoiceShow from "./InvoiceShow";
import { hide_checkbox } from "../comComps/comStyles";
import { hasWrite } from "../authProvider";
import { SaleRegion } from "./sale_region";

const listFilters = [
  // <DateInput source="date_gte" alwaysOn />,
  // <DateInput source="date_lte" alwaysOn />,
];

const BrandList = () => {
  return (
    <List
      // filters={listFilters}
      perPage={25}
      sort={{ field: "create_date", order: "desc" }}
      sx={hide_checkbox}
    >
      <Datagrid
        // rowClick="expand"
        rowClick="edit"
        // expand={<InvoiceShow />}
        sx={{
          "& .column-customer_id": {
            display: { xs: "none", md: "table-cell" },
          },
          "& .column-total_ex_taxes": {
            display: { xs: "none", md: "table-cell" },
          },
          "& .column-delivery_fees": {
            display: { xs: "none", md: "table-cell" },
          },
          "& .column-taxes": {
            display: { xs: "none", md: "table-cell" },
          },
          "& .column-contact_address_complete span": {
            width: "150px",
          },
        }}
      >
        <TextField source="id" />
        <TextField source="name" />
        <TextField source="company_name" label="Sub brand" />
        <TextField source="email" />
        <TextField source="phone" />
        <TextField label="Contact address" source="contact_address_complete" />
        <BooleanField source="active" />
        <SelectField
          source="team_id"
          choices={SaleRegion}
          label="Sale Region"
        />
        <DateField source="create_date" />
        {hasWrite("Brands") && <EditButton />}
      </Datagrid>
    </List>
  );
};

export default BrandList;
