import * as React from "react";
import { Fragment, useCallback } from "react";
import {
  AutocompleteInput,
  BooleanField,
  Count,
  DatagridConfigurable,
  DateField,
  DateInput,
  ExportButton,
  FilterButton,
  Link,
  List,
  NullableBooleanInput,
  NumberField,
  ReferenceField,
  ReferenceInput,
  SearchInput,
  SelectColumnsButton,
  TextField,
  TextInput,
  TopToolbar,
  useListContext,
  useRecordContext,
} from "react-admin";
import { useMediaQuery, Divider, Tabs, Tab, Theme } from "@mui/material";

import NbItemsField from "./NbItemsField";
import CustomerReferenceField from "../visitors/CustomerReferenceField";
import AddressField from "../visitors/AddressField";
import MobileGrid from "./MobileGrid";
import { Order, OrderStatus } from "../types";
import FullNameField from "../satistics/FullNameField";
import { hide_checkbox } from "../comComps/comStyles";

const ListActions = () => (
  <TopToolbar>
    <SelectColumnsButton />
    <FilterButton />
    <ExportButton />
  </TopToolbar>
);

const OrderList = () => (
  <List
    resource="orders"
    filterDefaultValues={{ status: 2 }}
    sort={{ field: "created_at", order: "DESC" }}
    perPage={25}
    filters={orderFilters}
    actions={<ListActions />}
  >
    <TabbedDatagrid />
  </List>
);



const orderFilters = [
  // <SearchInput source="q" alwaysOn />,
  // <ReferenceInput source="customer_id" reference="customers">
  //     <AutocompleteInput
  //         optionText={(choice?: Customer) =>
  //             choice?.id // the empty choice is { id: '' }
  //                 ? `${choice.first_name} ${choice.last_name}`
  //                 : ''
  //         }
  //     />
  // </ReferenceInput>,
  <DateInput source="date_gte" />,
  <DateInput source="date_lte" />,
  <TextInput source="total_gte" />,
  <NullableBooleanInput source="returned" />,
];

const tabs = [
  { id: OrderStatus.paid, name: "ordered" },
  { id: OrderStatus.processing, name: "processed" },
  // { id: OrderStatus.shipped, name: 'shipped' },
];

const TabbedDatagrid = () => {
  const listContext = useListContext();
  const { filterValues, setFilters, displayedFilters } = listContext;
  // console.dir(filterValues);
  const isXSmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("sm")
  );

  const handleChange = useCallback(
    (event: React.ChangeEvent<{}>, value: any) => {
      setFilters &&
        setFilters(
          { ...filterValues, status: value },
          displayedFilters,
          false // no debounce, we want the filter to fire immediately
        );
    },
    [displayedFilters, filterValues, setFilters]
  );

  return (
    <Fragment>
      <Tabs
        variant="fullWidth"
        centered
        value={filterValues.status}
        indicatorColor="primary"
        onChange={handleChange}
      >
        {tabs.map((choice) => (
          <Tab
            key={choice.id}
            label={
              <span>
                {choice.name} (
                <Count
                  filter={{
                    ...filterValues,
                    status: choice.id,
                  }}
                  sx={{ lineHeight: "inherit" }}
                />
                )
              </span>
            }
            value={choice.id}
          />
        ))}
      </Tabs>
      <Divider />
      {isXSmall ? (
        <MobileGrid />
      ) : (
        <>
          {filterValues.status === OrderStatus.paid && <OrderGrid />}
          {filterValues.status === OrderStatus.processing && (
            <OrderGrid />
          )}
        </>
      )}
    </Fragment>
  );
};

const OrderGrid = () => {
  return (
    <DatagridConfigurable
      isRowSelectable={() => false}
      rowClick="edit"
      sx={hide_checkbox}
    >
      <TextField source="id" />
      <DateField source="created_at" showTime />
      <TextField source="store" />
      <TextField source="reference" />
      <TextField source="transation_id" />
      <FullNameField label="Customer" />
      <TextField source="email" />
      <AddressField />
      <TextField source="currency" />
      <NumberField
        source="total"
        options={{
          style: "currency",
          currency: "USD",
        }}
        sx={{ fontWeight: "bold" }}
      />
      <NumberField source="quantity" />
    </DatagridConfigurable>
  );
};

export default OrderList;
