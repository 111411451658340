import * as React from "react";
import { required, SaveButton, TabbedForm, Toolbar } from "react-admin";
import { RichTextInput } from "ra-input-rich-text";

import { BrandEditDetails } from "./BrandEditDetails";
import { BrandImages } from "./BrandImages";
import { hasWrite } from "../authProvider";

const MyToolbar = () => {
  return hasWrite("Brands") ? (
    <Toolbar>
      <SaveButton type="button" />
    </Toolbar>
  ) : null;
};

const BrandEditForm = () => (
  <TabbedForm toolbar={<MyToolbar />}>
    <TabbedForm.Tab
      label="resources.products.tabs.details"
      sx={{ maxWidth: "40em" }}
    >
      <BrandEditDetails />
    </TabbedForm.Tab>
    <TabbedForm.Tab label="About us" path="about_us" sx={{ maxWidth: "40em" }}>
      <RichTextInput source="about_us" label="" />
    </TabbedForm.Tab>
    <TabbedForm.Tab
      label="shipping policy"
      path="shipping_policy"
      sx={{ maxWidth: "40em" }}
    >
      <RichTextInput source="shipping_policy" label="" />
    </TabbedForm.Tab>
    <TabbedForm.Tab
      label="return policy"
      path="return_policy"
      sx={{ maxWidth: "40em" }}
    >
      <RichTextInput source="return_policy" label="" />
    </TabbedForm.Tab>

    <TabbedForm.Tab label="Media" path="images" sx={{ maxWidth: "40em" }}>
      <BrandImages />
    </TabbedForm.Tab>
  </TabbedForm>
);

const req = [required()];

export default BrandEditForm;
