import * as React from 'react';
import {
    List,
    Datagrid,
    TextField,
    DateField,
    RaRecord,
    ImageField,
} from 'react-admin';

 
const ActionButtons = () => (
    <div className='rq_toolbar'>
        {/* <StatusButton /> */}
    </div>
);

// const exporter = (samples: any) => {
//     // console.dir(samples);
//     const selectedIds = samples.map(( sample : RaRecord ) => sample.id);
//     sample_exporter(selectedIds);
// };
 
const PartnerShipList = () => {
    return (
        <List
            // filters={listFilters}
            perPage={25}
            resource='partnership'
            sort={{ field: 'updated_at', order: 'desc' }}
          
          //  exporter={exporter}
        >
            <Datagrid
                rowClick='edit'
                bulkActionButtons={<ActionButtons />} 
                sx={{
                    '& .brand': {
                       fontWeight: 700,
                       marginRight: '20px'
                    },
                    '& .rq_toolbar': {
                        display: 'flex'
                     },
                     '& .img img': {
                        width: '50px',
                        height: '50px',
                     },
                }}
            >
                <TextField source="id" />
                <ImageField source="image" cellClassName='img' />
                <DateField source="updated_at" label="Date"/>
                <TextField source="title"/>
                <TextField source="brand"/>
                <TextField source="description" label="Program description" />
                <TextField source="qualifications" label="Creator qualifications" />
                <TextField source="status"/>
            </Datagrid>
        </List>
    );
};

export default PartnerShipList;
