import * as React from "react";
import {
  Edit,
  useRecordContext,
} from "react-admin";
import { NameRecord } from "../types";
import UserEditForm from "./UserEditForm";

const Title = () => {
  const record = useRecordContext<NameRecord>();
  return record ? <span>User: {record.fullname}</span> : null;
};
const UserEdit = () => (
  <div>
    <Edit title={<Title />}>
       <UserEditForm />
    </Edit>
  </div>
);
 

export default UserEdit;
