import * as React from 'react';
import { Admin, CustomRoutes, Resource, ListGuesser } from 'react-admin';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import { Route } from 'react-router';

import authProvider from './authProvider';
import { Login, Layout } from './layout';
import { Dashboard } from './dashboard';
import englishMessages from './i18n/en';
import { lightTheme } from './layout/themes';

import visitors from './visitors';
import SatisticsList from './satistics/SatisticsList';
import orders from './orders';
import stores from './stores';
import brands from './brands';
import users from './users';
import products from './products';
import invoices from './invoices';
import requestSample from './requestSample';
import partnershipApply from './partnershipApply';
import StoreProducts from './StoreProducts';
import categories from './categories';
import coupon from './coupon';
import tickets from './tickets';
import partnership from "./partnership";
import dataProviderFactory from './dataProvider';
import Configuration from './configuration/Configuration';
import Segments from './segments/Segments';
import { QueryClient } from 'react-query';

const i18nProvider = polyglotI18nProvider(locale => {
    if (locale === 'fr') {
        return import('./i18n/fr').then(messages => messages.default);
    }

    // Always fallback on english
    return englishMessages;
}, 'en');

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 5 * 60 * 1000, // 5 minutes
        },
    },
});

const App = () => {
    return (
        <Admin
            title=""
            dataProvider={dataProviderFactory(
                process.env.REACT_APP_DATA_PROVIDER || ''
            )}
            authProvider={authProvider}
            dashboard={Dashboard}
            loginPage={Login}
            layout={Layout}
            i18nProvider={i18nProvider}
            disableTelemetry
            theme={lightTheme}
           // queryClient={queryClient}
        >
           {/* <Resource name="users"  list={ListGuesser}  /> */}
            <CustomRoutes>
                <Route path="/configuration" element={<Configuration />} />
                <Route path="/segments" element={<Segments />} />
                <Route path="/satistics" element={<SatisticsList />} />
               
            </CustomRoutes>
            {/* <Resource name="satistics" {...satistics} /> */}
            <Resource name="stores" {...stores} />
            <Resource name="orders" {...orders} />
            <Resource name="coupon" {...coupon} />
            <Resource name="brands" {...brands} />
            <Resource name="users" {...users} />
            <Resource name="StoreProducts" {...StoreProducts} />
            <Resource name="customers" {...visitors} />
            <Resource name="requestSample" {...requestSample} />

            <Resource
                name="commands"
                {...orders}
                options={{ label: 'Orders' }}
            />
            <Resource name="invoices" {...invoices} />
            <Resource name="products" {...products} />
            <Resource name="categories" {...categories} />
            <Resource name="tickets" {...tickets} />
            <Resource name="partnership" {...partnership} />
            <Resource name="partnershipApply" {...partnershipApply} />
        </Admin>
    );
};

export default App;
