import PeopleIcon from '@mui/icons-material/People';
import UserList from './UserList';
import UserEdit from './UserEdit';
import UserCreate from './UserCreate';


export default {
    list: UserList,
    edit: UserEdit,
    create: UserCreate,
    icon: PeopleIcon,
};
