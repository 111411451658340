import { Fragment } from "react";
import { useListContext, ReferenceField, TextField } from "react-admin";
import {
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

import { NewMessageForm } from "./NewMessageForm";
import { ImagesField } from "./ImagesField";

export const MessageList = () => {
  const { data, isLoading } = useListContext();

  if (isLoading) return null;
  return (
    <List sx={{ width: "100%", pt: 0 }}>
      {data.map((message) => (
        <Fragment key={message.id}>
          <ListItem
            alignItems="flex-start"
            sx={
              {
                // backgroundColor: "#ffffef"
              }
            }
          >
            <ListItemText
              primary={
                <div>
                  <div>{message.message}</div>
                  <ImagesField image={message.image} />
                </div>
              }
              secondary={
                <ReferenceField record={ message } source="user_id" 
                reference="tickets/users" label="User" link={false}>
                  <TextField source="fullname" />
                </ReferenceField>
              }
              sx={{
                flexDirection: "column-reverse",
                display: "flex",
                gap: 0.5,
                "& .MuiListItemText-primary": {
                  whiteSpace: "pre-line",
                },
                "& .MuiListItemText-secondary span": {
                  fontWeight: "bold",
                },
              }}
            />
            <ListItemIcon sx={{ fontSize: "14px" }}>
              {new Date(message.created_at).toLocaleString(undefined, {
                dateStyle: "medium",
                timeStyle: "short",
              })}
            </ListItemIcon>
          </ListItem>
          <Divider component="li" />
        </Fragment>
      ))}
      <NewMessageForm />
    </List>
  );
};
