import * as React from "react";
import {
  DeleteButton,
  Edit,
  required,
  SaveButton,
  TabbedForm,
  Toolbar,
  TextInput,
  useRecordContext,
} from "react-admin";
import { RichTextInput } from "ra-input-rich-text";

import { ProductEditDetails } from "./ProductEditDetails";
import { Product } from "../types";
import ProductCategoryList from "../categories/ProductCategory";
import { ProductImageList } from "./ProductImages";
import { hasWrite } from "../authProvider";
import Variants from "./Variants";
import { Grid } from "@mui/material";
import { matchPath, useLocation } from "react-router";

const ProductTitle = () => {
  const record = useRecordContext<Product>();
  return record ? <span>Product: {record.name}</span> : null;
};

const MyToolbar = () => {
  return hasWrite("Products") ? (
    <Toolbar>
      <SaveButton type="button" />
      {/* <DeleteButton /> */}
    </Toolbar>
  ) : null;
};

const matchIt = (tabName: string) => {
  const pathname = useLocation().pathname;
  return !!matchPath("/products/:id/" + tabName, pathname);
};

const ProductEdit = () => {
  return (
    <div>
      <Edit title={<ProductTitle />}>
        <TabbedForm toolbar={<MyToolbar />}>
          <TabbedForm.Tab
            label="resources.products.tabs.details"
            // path="details"
            sx={{ maxWidth: "40em" }}
          >
            <Grid container columnSpacing={2}>
              <Grid item xs={12} sm={12}>
                <TextInput source="brand" disabled fullWidth />
              </Grid>
              <ProductEditDetails />
            </Grid>
          </TabbedForm.Tab>
          <TabbedForm.Tab
            label="resources.products.tabs.description"
            path="description"
            sx={{ maxWidth: "40em" }}
          >
            <RichTextInput source="description" label="" validate={req} />
          </TabbedForm.Tab>
          <TabbedForm.Tab label="Media" path="images" sx={{ maxWidth: "40em" }}>
            {matchIt("images") && <ProductImageList />}
          </TabbedForm.Tab>
          <TabbedForm.Tab label="Category" path="category">
            {matchIt("category") && <ProductCategoryList />}
          </TabbedForm.Tab>
          <TabbedForm.Tab
            label="Variants"
            path="variants"
            sx={{ maxWidth: "70em" }}
          >
            {matchIt("variants") && <Variants />}
          </TabbedForm.Tab>
        </TabbedForm>
      </Edit>
    </div>
  );
};

const req = [required()];

export default ProductEdit;
