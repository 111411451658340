import {
  useRecordContext,
  RaRecord,
  ArrayInput,
  SimpleFormIterator,
  TextInput,
  required,
  SelectInput,
  ImageInput,
  ImageField,
  CheckboxGroupInput,
  FieldProps,
  FormDataConsumer,
  NumberInput,
} from "react-admin";
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Grid,
  InputAdornment,
  Typography,
} from "@mui/material";

const Variants = () => {
  let record = useRecordContext<RaRecord>();
  if (!record) return null;
  let sizes: RaRecord[] = [];
  let colors: RaRecord[] = [];

  record.variants.forEach((v: any) => {
    if (v.variant_name == "Size") {
      sizes.push(v);
    }
    if (v.variant_name == "Color") {
      colors.push(v);
    }
  });
  record.colors = colors;
  record.sizes = sizes;
  let s_v = { variants1: sizes };
  return (
    <Box
      // display="flex"
      sx={{
        "& .var-img": {
          width: "unset",
        },
        "& .RaImageField-image": {
          width: "50px !important",
          height: "50px !important",
        },
        "& .title": {
          display: "block",
        },
        "& .variants": {
          display: "flex",
        },
        "& .variant": {
          display: "none",
        },
        "& .RaFileInput-dropZone": {
          padding: "0",
        },
        "& .RaFileInput-dropZone p": {
          margin: "0",
        },
      }}
    >
      <div className="title">
        <Typography variant="h6" gutterBottom>
          {"Size"}
        </Typography>
      </div>
      <ArrayInput source="sizes" fullWidth label="" className="variants">
        <SimpleFormIterator fullWidth inline>
          <TextInput
            source="variant"
            className="variant"
            validate={req}
            defaultValue={1}
          />
          <TextInput source="name" validate={req} />
          <NumberInput
            source="price"
            label="CAD Price"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
          />
          <NumberInput
            source="usd_price"
            label="USD Price"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
          />
          <NumberInput source="quantity" />
          <TextInput source="sku" label="SKU"/>
          <CheckboxGroupInput
            source="main"
            label="Default"
            choices={[{ id: 1, name: "Is default" }]}
          />
        </SimpleFormIterator>
      </ArrayInput>
      <div className="title">
        <Typography variant="h6" gutterBottom>
          {"Color"}
        </Typography>
      </div>
      <ArrayInput source="colors" fullWidth label="" className="variants">
        <SimpleFormIterator fullWidth inline>
          <TextInput
            source="variant"
            className="variant"
            validate={req}
            defaultValue={2}
          />
          <TextInput source="name" validate={req} />
          <NumberInput
            source="price"
            label="CAD Price"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
          />
          <NumberInput
            source="usd_price"
            label="USD Price"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
          />
          <NumberInput source="quantity" />
          <TextInput source="sku" label="SKU"/>
          <CheckboxGroupInput
            source="main"
            label="Default"
            choices={[{ id: 1, name: "Is default" }]}
          />

          <ImageInput source="image" className="var-img" label="">
            <ImageField source="src" title="title" />
          </ImageInput>
          <FormDataConsumer>
            {({ scopedFormData }) =>
              scopedFormData.id ? (
                <Images
                  v_id={scopedFormData.id}
                  vimg_ids={scopedFormData.images}
                  images={record.images}
                />
              ) : null
            }
          </FormDataConsumer>
        </SimpleFormIterator>
      </ArrayInput>
    </Box>
  );
};

export interface Props {
  images: RaRecord;
  v_id: number;
  vimg_ids: any;
}
const Images = (props: Props) => {
  let vimgs: number[] = [];
  const { vimg_ids, images, v_id } = props;
  if (vimg_ids) {
    vimg_ids.split(",").forEach((vi: string) => {
      vimgs.push(parseInt(vi));
    });
  }
  return (
    <Grid container display={"flex"} spacing={{ xs: 2, md: 3 }}>
      {images.map((img: any, i: number) => (
        <Grid key={i + "_" + v_id} item>
          <Card
            key={i}
            sx={{
              "& .MuiCardContent-root": {
                padding: "2px",
              },
              "& .MuiCardContent-root:last-child": {
                padding: "2px",
              },
              "& .MuiFormHelperText-root": {
                display: "none",
              },
            }}
          >
            <CardMedia
              image={img.imagePath}
              sx={{ height: "60px", width: "60px" }}
              component="img"
              loading="lazy" 
            />
            <CardContent sx={{ textAlign: "center" }}>
              <CheckboxGroupInput
                source="linked"
                name={"vimg_" + v_id + "_" + img.id}
                defaultValue={vimgs.includes(img.id) ? ["1"] : []}
                label=""
                choices={[{ id: 1, name: "" }]}
              />
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

const req = [required()];
export default Variants;
