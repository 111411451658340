import InvoiceIcon from '@mui/icons-material/LibraryBooks';

import PartnerShipList from './PartnerShipList';
import PartnerShipEdit from './PartnerShipEdit';
import PartnerShipCreate from './PartnerShipCreate';

export default {
    list: PartnerShipList,
    edit: PartnerShipEdit,
    create: PartnerShipCreate,
    icon: InvoiceIcon,
};
