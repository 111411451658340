import * as React from "react";
import {
  BooleanInput,
  RaRecord,
  required,
  SaveButton,
  SelectArrayInput,
  SelectInput,
  SimpleForm,
  TextInput,
  Toolbar,
  useGetList,
} from "react-admin";

import { Grid, Typography } from "@mui/material";

const UserEditDetail = () => {
  return (
    <Grid container columnSpacing={2}>
      <Grid item xs={12} sm={6}>
        <TextInput source="fullname" fullWidth validate={req} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextInput source="companyname" fullWidth validate={req} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextInput source="email" fullWidth validate={req} />
      </Grid>
      <Grid item xs={12} sm={3}>
        <SelectInput
          source="userrole"
          label="User type"
          choices={[
            { id: "Admin", name: "Admin" },
            { id: "Staff", name: "Staff" },
            { id: "Merchant", name: "Merchant" },
          ]}
        />
      </Grid>
      <Grid item xs={6} sm={3}>
        <BooleanInput source="lockoutenabled" label="Locked" />
      </Grid>
      <Grid item xs={12} sm={12}>
        <Typography variant="h6" gutterBottom>
          {"Address"}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12}>
        <TextInput source="address1" fullWidth validate={req} />
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextInput source="city" fullWidth validate={req} />
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextInput source="province" fullWidth validate={req} />
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextInput source="postcode" fullWidth validate={req} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <SelectInput
          source="country"
          choices={[
            { id: "Canada", name: "Canada" },
            { id: "United States", name: "United States" },
          ]}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextInput source="tel" fullWidth validate={req} />
      </Grid>
    </Grid>
  );
};

const req = [required()];

export default UserEditDetail;
