import * as React from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@mui/material';
import { Link, useTranslate, useGetMany, useRecordContext } from 'react-admin';

import { Order, Product } from '../types';
import { TableCellRight } from './TableCellRight';

const Basket = () => {
    const record = useRecordContext<Order>();
    const translate = useTranslate();

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>
                        {'Name'}
                    </TableCell>
                    <TableCell>
                        {'Brand'}
                    </TableCell>
                    <TableCell>
                        {'Color'}
                    </TableCell>
                    <TableCell>
                        {'Size'}
                    </TableCell>
                    <TableCellRight>
                        {translate(
                            'resources.commands.fields.basket.unit_price'
                        )}
                    </TableCellRight>
                    <TableCellRight>
                        {translate('resources.commands.fields.basket.quantity')}
                    </TableCellRight>
                    <TableCellRight>
                        {translate('resources.commands.fields.basket.total')}
                    </TableCellRight>
                </TableRow>
            </TableHead>
            <TableBody>
                {record?.basket?.map((item: any) => (
                    <TableRow key={item.product_id}>
                        <TableCell>
                            <Link to={`/products/${item.product_id}`}>
                                {item.product_name}
                            </Link>
                        </TableCell>
                        <TableCell>
                            <Link to={`/brands/${item.brand}`}>
                                {item.brand}
                            </Link>
                        </TableCell>
                        <TableCell style={{whiteSpace: 'nowrap'}}>
                            {item.color}
                        </TableCell>
                        <TableCell style={{whiteSpace: 'nowrap'}}>
                            {item.size}
                        </TableCell>
                        <TableCellRight>
                            {item.price.toLocaleString(
                                undefined,
                                {
                                    style: 'currency',
                                    currency: 'USD',
                                }
                            )}
                        </TableCellRight>
                        <TableCellRight>{item.quantity}</TableCellRight>
                        <TableCellRight>
                            {(
                                item.price *
                                item.quantity
                            ).toLocaleString(undefined, {
                                style: 'currency',
                                currency: 'USD',
                            })}
                        </TableCellRight>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
};

export default Basket;
