import { DataProvider, fetchUtils, Options } from 'react-admin';
import { stringify } from 'query-string';
import authProvider from '../authProvider';
import { fetchJson } from './fetchJson';

const get_options = (options: any = {}) => {
    const token = localStorage.getItem('ba_token');
    if (token) { 
        let headers = new Headers({
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
        })
        return {...options, headers};
    }
    return options;
}

const get_form_options = (options: any = {}) => {
    const token = localStorage.getItem('ba_token');
    if (token) { 
        let headers = new Headers({
            Authorization: `Bearer ${token}`,
            //'Content-Type': 'application/json',
        })
        return {...options, headers};
    }
    return options;
}

const get_url = (apiUrl: string, resource: string) => {
    return `${process.env.API_URL}/napi${apiUrl}/${resource}`
}

const clear_undefined = (params: any) => {
    for ( const param in params.data ) {
        if (params.data[param] === undefined
            || params.data[param] == 'undefined'){
                params.data[param] = '';
        }
    }
}

const file_data = (params: any): FormData => {
    const formData = new FormData();
    for ( const param in params.data ) {
       // console.dir(params);
        // 1 file
        if (param === 'image') {
            if(params.data[param]?.rawFile) {
                formData.append('file', params.data[param].rawFile);
            }
            continue;
        }
    
        if (param === 'n_avatar' || param === 'n_image'){
            if(params.data[param]?.rawFile) {
                formData.append('file_' + param, params.data[param].rawFile);
            }
            continue;
        }
        // when using multiple files
        if ((param === 'files' || param === 'new_images')
            && !!params.data[param])
        {
          params.data[param].forEach((file: any) => {
            formData.append('files', file.rawFile);
          });
          continue;
        }
    
        formData.append(param, params.data[param]);
      }
    return formData;
}

const checkLogin = (error: any) => {
    if(error && (error.status == 422 ||  error.status == 401)){
        authProvider.logout({});
        window.location.href = '/#/login'
    }
};

const getProductPostData = ( params: any): FormData => {

    let formData = file_data(params);
    
    let variants: any = [];
    let v_images: any = {};
    Object.keys(params.data).forEach(key => {
        // if(key.startsWith('vimg_')){
        //     console.dir(params.data[key].toString());
        // }
        if(key.startsWith('vimg_') && params.data[key].toString() == "1"){
            let ks = key.split('_');
            if(ks[1] in v_images){
                v_images[ks[1]].push(ks[2]);
            }else{
                v_images[ks[1]] = [ks[2]];
            }
        }
    });
    // console.dir(v_images);
    // console.dir(params);
    let v_idx = 0;
    if (params.data['sizes']){
        params.data['sizes'].forEach((v: any, idx: any) => {
            v_idx += idx;
            v['idx'] = v_idx;
            variants.push(v);
            delete v['image'];
        });
    }
    v_idx++;
    if (params.data['colors']){
        params.data['colors'].forEach((v: any, idx: any) => {
            v_idx += idx;
            v['idx'] = v_idx;
           if(v_images[v['id']]){
            v['images'] = v_images[v['id']].join(',');
           }
            variants.push(v);
            if(v['image'] && v['image'].rawFile){
                formData.append('variant_' + v_idx, v['image'].rawFile)
            }
            delete v['image'];
        })
    }
    formData.append('variants_str', JSON.stringify(variants));
    return formData;
}

export default (apiUrl: string, httpClient = fetchJson): DataProvider => ({
    getList: (resource, params) => {
        // console.dir(params);
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const query = {
            ...fetchUtils.flattenObject(params.filter),
            _sort: field,
            _order: order,
            _start: (page - 1) * perPage,
            _end: page * perPage,
        };
        const url = `${get_url(apiUrl, resource)}?${stringify(query)}`;
       
        return httpClient(url, get_options()).then(({ headers, json }) => {
            return json;
        }).catch((error) => {
            checkLogin(error);
        });
    },

    getOne: (resource, params) =>{
        if(params.id && params.id === 'create'){
            return Promise.resolve({ data: params });
        }
        return httpClient(`${get_url(apiUrl, resource)}/${params.id}`, get_options())
        .then(({ json }) => json)
        .catch((error) => {
            checkLogin(error);
        });
    },

    getMany: (resource, params) => {
        const query = {
            id: params.ids.join(','),
        };
        const url = `${get_url(apiUrl, resource)}?${stringify(query)}`;
        return httpClient(url, get_options()).then(({ json }) =>  json )
        .catch((error) => {
            checkLogin(error);
        });
    },

    getManyReference: (resource, params) => {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const query = {
            ...fetchUtils.flattenObject(params.filter),
            [params.target]: params.id,
            _sort: field,
            _order: order,
            _start: (page - 1) * perPage,
            _end: page * perPage,
        };
        const url = `${get_url(apiUrl, resource)}?${stringify(query)}`;

        return httpClient(url, get_options()).then(({ headers, json }) => {
            return json;
        }).catch((error) => {
            checkLogin(error);
        });
    },

    update: (resource, params) => {
        clear_undefined(params);
        const url = `${get_url(apiUrl, resource)}/${params.id}`
        if(resource == 'categories'){
            return httpClient(url, get_form_options({
                method: 'POST',
                body: file_data(params),
              })).then(({ json }) => json);
        }

        if(resource == 'products'){
            return httpClient(url, get_form_options({
                method: 'POST',
                body: getProductPostData(params),
              })).then(({ json }) => json);
        }

        if(resource == 'brands'){
            return httpClient(url, get_form_options({
                method: 'POST',
                body: file_data(params),
              })).then(({ json }) => json);
        }
        if(resource == 'partnership'){
            return httpClient(url, get_form_options({
                method: 'PUT',
                body: file_data(params),
              })).then(({ json }) => json);
        }

        return httpClient(url, get_options({
            method: 'PUT',
            body: JSON.stringify(params.data),
        })).then(({ json }) => json)
        .catch((error) => {
            checkLogin(error);
        });
    },

    // json-server doesn't handle filters on UPDATE route, so we fallback to calling UPDATE n times instead
    updateMany: (resource, params) => {
        clear_undefined(params);
       return Promise.all(
                params.ids.map(id =>
                    httpClient(`${get_url(apiUrl, resource)}/${id}`, get_options(
                        {
                            method: 'PUT',
                            body: JSON.stringify(params.data),
                        }
                    ))
                )
            ).then(responses => ({ data: responses.map(({ json }) => json.id) }))
    },

    create: (resource, params) =>{
        clear_undefined(params);
        const url = `${get_url(apiUrl, resource)}`
        if(resource == 'brands' || resource == 'tickets/message'){
            return httpClient(url, get_form_options({
                method: 'POST',
                body: file_data(params),
              })).then(({ json }) => json);
        }

        if(resource == 'partnership'){
            return httpClient(url, get_form_options({
                method: 'POST',
                body: file_data(params),
              })).then(({ json }) => json);
        }


       return httpClient(url, get_options({
            method: 'POST',
            body: JSON.stringify(params.data),
        })).then(({ json }) => json)
        .catch((error) => {
            checkLogin(error);
        });
    },

    delete: (resource, params) =>
        httpClient(`${get_url(apiUrl, resource)}/${params.id}`, get_options({
            method: 'DELETE',
        })).then(({ json }) => json)
        .catch((error) => {
            checkLogin(error);
        }),

    // json-server doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
    deleteMany: (resource, params) =>
        Promise.all(
            params.ids.map(id =>
                httpClient(`${get_url(apiUrl, resource)}/${id}`, get_options({
                    method: 'DELETE',
                }))
            )
        ).then(responses => ({ data: responses.map(({ json }) => json.id) })),
});