import * as React from "react";
import {
  BooleanInput,
  Datagrid,
  FieldProps,
  List,
  ListBase,
  RaRecord,
  required,
  SaveButton,
  SelectArrayInput,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  Toolbar,
  useGetList,
  useRecordContext,
} from "react-admin";

import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
} from "@mui/material";
import UserEditRole from "./UserEditRole";

const UserEditRoles = () => {
  let roles_ = [];
  const record = useRecordContext<RaRecord>();
  if (record) {
    roles_ = record.roles;
  } else {
    const { data: roles } = useGetList<RaRecord>(`users/roles`, {
      pagination: { page: 1, perPage: 1000 },
    });
    if (!roles) return null;
    roles_ = roles;
  }
  return (
    <div>
    
    <ListBase>
      <Datagrid
        data={roles_}
        sx={{
          "& .MuiTableHead-root": {
            display: "none",
          },
          width: '100%'
        }}
      >
        <UserEditRole />
      </Datagrid>
    </ListBase>
    </div>
  );
};

const RoleInputs = (props: FieldProps<RaRecord>) => {
  return (
    <>
      <TextInput source="role" />
      <BooleanInput source="read" />
      <BooleanInput source="write" />
    </>
  );
};

const req = [required()];

export default UserEditRoles;
