import {
  Edit,
  SimpleForm,
  TextInput,
  useRecordContext,
  EditProps,
  NumberInput,
} from "react-admin";

import { Category } from "../types";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

interface Props extends EditProps<Category> {
  onCancel: () => void;
}

const styles = {
  parentPath: { margin: "0.6rem", fontWeight: "500", fontSize: "1rem" },
  hidden: { display: "none" },
};

const CategoryCreate = ({ onCancel, ...props }: Props) => {
  return (
    <Edit
      {...props}
      sx={{
        "& .ra-delete-button": {
          display: "none",
        },
      }}
    >
      <Box pt={5} width={{ xs: "100vW", sm: 400 }} mt={{ xs: 2, sm: 1 }}>
        <Stack direction="row" p={2}>
          <Typography variant="h6" flex="1">
            {"Create"}
          </Typography>
          <IconButton onClick={onCancel} size="small">
            <CloseIcon />
          </IconButton>
        </Stack>
        <SimpleForm
          sx={{
            "& .MuiTypography-root": {
              margin: "0.6rem",
              fontWeight: "500",
              fontSize: "1rem",
            },
            "& .ra-delete-button": {
              display: "none",
            },
          }}
        >
          <CategoryInputs />
        </SimpleForm>
      </Box>
    </Edit>
  );
};

const CategoryInputs = () => {
  const record = useRecordContext<Category>();
  if (!record) {
    return null;
  }

  let parents = "";
  let parent_paths = record.complete_name
    ? record.complete_name.split("/")
    : [];

  let p_c = 0;
  if (parent_paths && parent_paths.length >= p_c) {
    parent_paths = parent_paths.slice(0, parent_paths.length - p_c);
    parents = parent_paths.join(" / ");
  }

  return (
    <>
      <TextInput
        source="p_name"
        label="Parents Path"
        disabled={true}
        defaultValue={parents}
      />
      <TextInput source="n_name" label="Name" />
      <NumberInput source="order" />
      <TextInput
        source="action"
        defaultValue={"create"}
        style={styles.hidden}
      />
    </>
  );
};

export default CategoryCreate;
