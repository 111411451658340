import * as React from "react";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import {
  useTranslate,
  useTheme,
  Title,
  SimpleForm,
  PasswordInput,
  useCreate,
  useNotify,
} from "react-admin";

import { darkTheme, lightTheme } from "../layout/themes";
import { Stack, Typography } from "@mui/material";

export const validateForm = (
  values: Record<string, any>
): Record<string, any> => {
  const min = 8;
  const err_msg = "Should be at least 8 characters";
  const errors = {} as any;
  if (!values.oldPassword || values.oldPassword.length < min) {
    errors.oldPassword = err_msg;
  }
  if (!values.password || values.password.length < min) {
    errors.password = err_msg;
  }
  if (!values.confirmPassword || values.confirmPassword.length < min) {
    errors.confirmPassword = err_msg;
  }
  if (values.password && values.password !== values.confirmPassword) {
    errors.confirmPassword = "resources.customers.errors.password_mismatch";
  }
  return errors;
};
const Configuration = () => {
  const translate = useTranslate();
  const notify = useNotify();
  const [theme, setTheme] = useTheme();
  const [create] = useCreate();
  const postSave = (in_data: any) => {
    create("users/updatepwd", { data: in_data }, {onSuccess: (data) => {
      if (data && data.id == -1){
        notify(`Error: ${data.message}`, {
          type: 'error',
        });
      }else{
        notify('Password updated', {
          type: 'info',
        });
      }
    }});
  };
  return (
    <Card>
      <Title title={translate("pos.configuration")} />
      <CardContent>
        <Box sx={{ width: "10em", display: "inline-block" }}>
          {translate("pos.theme.name")}
        </Box>
        <Button
          variant="contained"
          sx={{ margin: "1em" }}
          color={theme?.palette?.mode === "light" ? "primary" : "secondary"}
          onClick={() => setTheme(lightTheme)}
        >
          {translate("pos.theme.light")}
        </Button>
        <Button
          variant="contained"
          sx={{ margin: "1em" }}
          color={theme?.palette?.mode === "dark" ? "primary" : "secondary"}
          onClick={() => setTheme(darkTheme)}
        >
          {translate("pos.theme.dark")}
        </Button>
      </CardContent>
      <CardContent>
        <Stack direction="row" p={2}>
          <Typography variant="h6" flex="1">
            {"Change password"}
          </Typography>
        </Stack>
        <SimpleForm
          validate={validateForm}
          onSubmit={postSave}
          sx={{
            "& .MuiTypography-root": {
              margin: "0.6rem",
              fontWeight: "500",
              fontSize: "1rem",
            },
            "& .ra-delete-button": {
              display: "none",
            },
          }}
        >
          <PasswordInput source="oldPassword" />
          <PasswordInput source="password" />
          <PasswordInput source="confirmPassword" />
        </SimpleForm>
      </CardContent>
    </Card>
  );
};

export default Configuration;
 