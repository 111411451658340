import {
  TextField,
  Datagrid,
  useRecordContext,
  CheckboxGroupInput,
  useStore,
  ListBase,
  useListContext,
} from "react-admin";
import { useFormContext } from "react-hook-form";
import { Box, Grid } from "@mui/material";
import { Category, Product } from "../types";
import { useCallback } from "react";
import _ from "lodash";

interface Props {
  prod?: Product;
  ids?: any[];
  selectHander?: (vals: any) => void;
  values?: any;
}

const styles = {
  rightCol: { flex: 1, marginLeft: "40px" },
  selected: { marginLeft: "40px", fontWeight: 500, width: "20rem" },
  tableCell: { fontWeight: 500, lineHeight: "1.5rem", padding: "6px 16px" },
};

const get_selected = (ids: any[], categories: any, selected: any[]): any => {
  if (ids && categories) {
    for (var i = 0; i < categories.length; i++) {
      let c = categories[i];
      if (ids.indexOf(c.id) > -1) {
        selected.push(c);
      } else {
        get_selected(ids, c.children, selected);
      }
    }
  }
};

const ProductCategoryList = () => {
  const prod = useRecordContext<Product>();

  if (!prod) {
    return null;
  }

  let sIds: any[] = [];

  if (prod.categories) {
    prod.categories.forEach((c) => {
      sIds.push(c.category_id);
    });
  }
  const [value, setValue] = useStore("prod.categories." + prod.id, sIds);
  const selectHander = useCallback(
    (vals) => {
      setValue(vals);
    },
    [sIds]
  );
  return (
    // <Box display="flex">
      <ListBase resource="categories" perPage={100} key={"prod_" + prod.id}>
        <Grid container spacing={2}>
          <Grid item xs={6} md={4}>
            <SelectedList prod={prod} values={value} />
          </Grid>
          <Grid item xs={6} md={8}>
            <Datagrid
              rowClick="expand"
              expand={
                <CategoryGrid1
                  prod={prod}
                  ids={sIds}
                  selectHander={selectHander}
                />
              }
              isRowSelectable={() => false}
              bulkActionButtons={false}
            >
              <TextField source="name" sortable={false} />
              <TextField source="complete_name" sortable={false} />
            </Datagrid>
          </Grid>
        </Grid>
      </ListBase>
    // </Box>
  );
};

const SelectedList = (props: { prod: Product; values: any }) => {
  const { data: categories } = useListContext();
  const { getValues } = useFormContext();
  if (!categories) {
    // return null;
  }
  const { prod, values } = props;
  let ids = getValues("m_category");
  let selectList: Category[] = [];
  get_selected(ids, categories, selectList);
 // console.dir(selectList);
  return (
    <Datagrid
      data={selectList}
      isRowSelectable={() => true}
      bulkActionButtons={false}
      sx={{
        backgroundColor: "#f5f5f5",
        "& .MuiTableCell-head": {
          fontWeight: 600,
          backgroundColor: "#f5f5f5",
        },
        "& .MuiTypography-root": {
          fontWeight: 500,
        },
      }}
    >
      <TextField
        source="complete_name"
        label="Selected categories"
        sortable={false}
      />
    </Datagrid>
  );
};
const CategoryGrid1 = (props: Props) => {
  const record = useRecordContext<Category>();
  if (!record || !record.children) return null;
  const { prod, ids, selectHander } = props;
  return (
    <div style={styles.rightCol} className="sub_cate">
      <Datagrid
        data={record.children}
        isRowSelectable={() => false}
        rowClick="expand"
        expand={
          <CategoryGrid2 prod={prod} ids={ids} selectHander={selectHander} />
        }
        bulkActionButtons={false}
        sx={{
          "& .MuiTableHead-root": {
            display: "none",
          },
        }}
      >
        <TextField source="name" sortable={false} />
        <TextField source="complete_name" sortable={false} />
      </Datagrid>
    </div>
  );
};

const CategoryGrid2 = (props: Props) => {
  const record = useRecordContext<Category>();
  if (!record || !record.children) return null;
  const { prod, ids, selectHander, values } = props;
  let cates = record.children.reduce((all: any[], c) => {
    return [...all, { id: c.id, name: c.name }];
  }, []);
  return (
    <div style={styles.rightCol}>
      <CheckboxGroupInput
        source="m_category"
        label=""
        helperText=""
        defaultValue={ids}
        choices={cates}
        onChange={selectHander}
        sx={{
          "&.ra-input-category": {
            verticalAlign: "unset",
            margin: "0px",
          },
        }}
      />
    </div>
  );
};
 
export default ProductCategoryList;
