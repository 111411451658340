import * as React from "react";
import { Table, TableBody, TableCell, TableRow } from "@mui/material";
import { useRecordContext, useTranslate } from "react-admin";

import { Order } from "../types";
import { TableCellRight } from "./TableCellRight";

const Totals = () => {
  const record = useRecordContext<Order>();
  const translate = useTranslate();

  return (
    <Table sx={{ minWidth: "35em" }}>
      <TableBody>
        <TableRow>
          <TableCell>{"Sub Total"}</TableCell>
          <TableCellRight>
            {record?.subtotal.toLocaleString(undefined, {
              style: "currency",
              currency: "USD",
            })}
          </TableCellRight>
        </TableRow>
        <TableRow>
          <TableCell>
            {translate("resources.commands.fields.basket.delivery")}
          </TableCell>
          <TableCellRight>
            {record?.ship_value.toLocaleString(undefined, {
              style: "currency",
              currency: "USD",
            })}
          </TableCellRight>
        </TableRow>
        {record?.promotion_name !== "" && (
          <TableRow>
            <TableCell>
              {record?.promotion_name}
            </TableCell>
            <TableCellRight>
              {(record?.total - record?.subtotal - record?.tax_value).toLocaleString(undefined, {
                style: "currency",
                currency: "USD",
              })}
            </TableCellRight>
          </TableRow>
        )}

      {record?.taxes?.map((item: any) => (
        <TableRow>
          <TableCell>
            {translate("resources.commands.fields.basket.taxes")} (
            {item?.tax_name})
          </TableCell>
          <TableCellRight>
            {item?.tax_value.toLocaleString(undefined, {
              style: "currency",
              currency: "USD",
            })}
          </TableCellRight>
        </TableRow>))}

        <TableRow>
          <TableCell sx={{ fontWeight: "bold" }}>
            {translate("resources.commands.fields.basket.total")}
          </TableCell>
          <TableCellRight sx={{ fontWeight: "bold" }}>
            {record?.total.toLocaleString(undefined, {
              style: "currency",
              currency: "USD",
            })}
          </TableCellRight>
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default Totals;
