import {
  Edit,
  SimpleForm,
  TextInput,
  useRecordContext,
  EditProps,
  NumberInput,
  ImageInput,
  ImageField,
} from "react-admin";

import { Category } from "../types";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

interface Props extends EditProps<Category> {
  onCancel: () => void;
}

const styles = {
  parentPath: { margin: "0.6rem", fontWeight: "500", fontSize: "1rem" },
  hidden: { display: "none" },
};

const CategoryEdit = ({ onCancel, ...props }: Props) => {
  return (
    <Edit {...props}>
      <Box pt={5} width={{ xs: "100vW", sm: 400 }} mt={{ xs: 2, sm: 1 }}>
        <Stack direction="row" p={2}>
          <Typography variant="h6" flex="1">
            {"Update"}
          </Typography>
          <IconButton onClick={onCancel} size="small">
            <CloseIcon />
          </IconButton>
        </Stack>
        <SimpleForm
          sx={{
            "& .MuiTypography-root": {
              margin: "0.6rem",
              fontWeight: "500",
              fontSize: "1rem",
            },
          }}
        >
          <CategoryInputs />
          {/* <ImageField source="image" title="title" /> */}
          <ImageInput source="image">
            <ImageField source="src" title="title" />
          </ImageInput>
        </SimpleForm>
      </Box>
    </Edit>
  );
};

const CategoryInputs = () => {
  const record = useRecordContext<Category>();
  if (!record) {
    return null;
  }
  let parents = "";
  let parent_paths = record.complete_name
    ? record.complete_name.split("/")
    : [];
  if (parent_paths && parent_paths.length >= 1) {
    parent_paths = parent_paths.slice(0, parent_paths.length - 1);
    parents = parent_paths.join(" / ");
  }

  return (
    <>
      <TextInput
        source="p_name"
        label="Parents Path"
        disabled={true}
        defaultValue={parents}
      />
      <TextInput source="name" label="Name" />
      <NumberInput source="order" />
    </>
  );
};

export default CategoryEdit;
