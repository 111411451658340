export const SaleRegion = [
  {
    id: 0,
    name: "All",
  },
  {
    id: 1,
    name: "United States",
  },
  {
    id: 2,
    name: "Canada",
  }
];


