import { Link } from "react-router-dom";
import {
  Button,
  EditButtonProps,
  RaRecord,
  useRecordContext,
} from "react-admin";
import ContentAdd from "@mui/icons-material/Add";

const AddButton = <RecordType extends RaRecord = any>(
  props: EditButtonProps<RecordType>
) => {
  const {
    icon = defaultIcon,
    label = "ra.action.create",
    scrollToTop = true,
    ...rest
  } = props;
  const record = useRecordContext(props);
  let id = record? record.id: '0';
  return (
    <Button
      component={Link}
      to={`${id}/create`}
      state={scrollToTop === true ? { _scrollToTop: true } : {}}
      label={label}
      onClick={stopPropagation}
      {...(rest as any)}
    >
      {icon}
    </Button>
  );
};
const stopPropagation = (e: { stopPropagation: () => any }) =>
  e.stopPropagation();
const defaultIcon = <ContentAdd />;
export default AddButton;
