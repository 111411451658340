import * as React from 'react';
import { Box, Chip, useMediaQuery, Theme } from '@mui/material';
import {
    CreateButton,
    ExportButton,
    FilterButton,
    FilterForm,
    FilterContext,
    InputProps,
    ListBase,
    NumberInput,
    Pagination,
    ReferenceInput,
    SearchInput,
    SelectInput,
    SortButton,
    Title,
    TopToolbar,
    useTranslate,
    useGetResourceLabel,
    useGetList,
} from 'react-admin';

import ImageList from './GridList';
import Aside from './Aside';
import { hasRole, hasWrite } from '../authProvider';
import { Sort } from '../utils/sort';

const ProductList = () => {
    const getResourceLabel = useGetResourceLabel();
    const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('md'));
    const { data } = useGetList(
        "brands/id_names",
        { pagination: { page: 1, perPage: 1000 } }
      );
     
    let brands = data? data: [];

    return (
        <ListBase perPage={24} sort={{ field: 'brand', order: 'ASC' }}>
            <Title defaultTitle={getResourceLabel('products', 2)} />
            <FilterContext.Provider value={productFilters({brands: brands})}>
                <ListActions />
                <Box m={1}>
                        <FilterForm />
                    </Box>
            </FilterContext.Provider>
            <Box display="flex">
                {/* <Aside /> */}
                <Box width={isSmall ? 'auto' : 'calc(100%)'}>
                    <ImageList />
                    <Pagination rowsPerPageOptions={[12, 24, 48, 72]} />
                </Box>
            </Box>
        </ListBase>
    );
};

const QuickFilter = ({ label }: InputProps) => {
    const translate = useTranslate();
    return <Chip sx={{ mb: 1 }} label={translate(label as string)} />;
};


 
export const productFilters = ( {brands}: any ) => {
    return [
        <SearchInput source="q" alwaysOn />,
        <SelectInput source="merchant" alwaysOn choices={brands.sort(Sort)} />,
    ]
} ;

const ListActions = ({ isSmall }: any) => (
    <TopToolbar sx={{ minHeight: { sm: 56 } }}>
        <FilterButton />
        <SortButton fields={['name', 'brand', 'list_price']} />
        {hasWrite("Products") && <CreateButton />}
        <ExportButton />
    </TopToolbar>
);

export default ProductList;
