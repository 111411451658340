import {
  ImageField,
  ImageInput,
  RadioButtonGroupInput,
  TextInput,
  useDelete,
  useRefresh ,
  useRecordContext,
  Button 
} from "react-admin";

import { Grid, Card, CardMedia, CardContent} from "@mui/material";

import { Product } from "../types";
import { RemoveCircle } from "@mui/icons-material";


export const ProductImageList = () => {
  const record = useRecordContext<Product>();
  if (!record) return null;
  const [deleteOne, { isLoading, error }] = useDelete();
  const refresh = useRefresh();
  let imgs = record.images;
  const handleClick = (id: any) => {
    deleteOne(
        'products/image',
        { id: id }
    );
    refresh();
  }
  return (
    <div>
      <Grid
        container
        spacing={{ xs: 2, md: 3 }}
        columns={{ xs: 4, sm: 8, md: 12 }}
      >
        <Grid item xs={12} sm={12}>
          <TextInput source="video_url" fullWidth />
        </Grid>
        {imgs.map((img, i) => (
          <Grid
            key={i + "_" + record.id}
            xs={12}
            sm={6}
            md={4}
            lg={3}
            xl={2}
            item
          >
            <Card
              key={i}
              sx={{
                "& .MuiCardContent-root": {
                  padding: "5px",
                },
                "& .MuiCardContent-root:last-child": {
                  padding: "5px",
                },
              }}
            >
              <CardMedia
                image={img.imagePath}
                sx={{ height: 140 }}
                component="img"
                loading="lazy" 
              />
              <CardContent
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                <RadioButtonGroupInput
                  key={"r_" + i}
                  source="main_image_id"
                  value={img.id}
                  choices={[{ id: img.id, name: "Main", key: "k_" + i }]}
                  sx={{
                    "& legend": {
                      display: "none",
                    },
                    "& .MuiFormHelperText-root": {
                      display: "none",
                    },
                  }}
                />
                
                <Button onClick={() => handleClick(img.id)}>
                  <RemoveCircle sx={{ color: "#d32f2f;" }} />
                </Button>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
      <ImageInput source="new_images" multiple>
        <ImageField source="src" />
      </ImageInput>
    </div>
  );
};
