import { useRef } from "react";
import {
  Form,
  ImageField,
  ImageInput,
  required,
  SelectInput,
  TextInput,
  useCreate,
  useGetIdentity,
  useListContext,
  useNotify,
  useRecordContext,
  useRedirect,
  ReferenceInput,
} from "react-admin";
import { useFormContext } from "react-hook-form";
import {
  Button,
  ListItem,
  ListItemText,
} from "@mui/material";

import { v4 as uuidv4 } from "uuid";
import { STATUS_choises } from "./StatusField";
import { PRIORITY_choises } from "./PriorityField";

export const NewMessageForm = (prop: { isCreate?: boolean }) => {
  const { refetch } = useListContext();
  let record = useRecordContext();
  if (record == null) {
    record = { id: 0, status: 0 };
  }
  const [create, { isLoading: isCreating }] = useCreate();
  const resetForm = useRef<any>();
  const { identity } = useGetIdentity();
  const isFormDisabled = record.status === 5;
  const notify = useNotify();
  const redirect = useRedirect();

  const handleSubmit = (values: any) => {
    const { status, ...message } = values;
    create(
      "tickets/message",
      {
        data: {
          id: uuidv4(),
          ...message,
          ticket_id: record.id,
          status,
        },
      },
      {
        onSuccess: () => {
          notify("Submit success!", {
            type: "info",
          });
          resetForm.current && resetForm.current();
          try {
            if (record.id == 0) {
              redirect("/tickets");
            } else {
              refetch();
            }
          } catch (error) {}
        },
        onError: () => {
          notify("Submit failed!", {
            type: "error",
          });
        },
      }
    );
  };

  return (
    <ListItem
      alignItems="flex-start"
      sx={prop.isCreate ? {} : { backgroundColor: "#ffffef" }}
    >
      <ListItemText
        sx={{
          width: "100%",
          flexDirection: "column-reverse",
          display: "flex",
          gap: 0.5,
          "& .MuiListItemText-primary": {
            whiteSpace: "pre-line",
          },
          "& .MuiListItemText-secondary": {},
        }}
        secondary={`${identity?.fullName}`}
      >
        <Form
          onSubmit={handleSubmit}
          record={{
            status: record.status,
          }}
        >
          <SetFormContext resetForm={resetForm} />
          {prop.isCreate && (
            <>
              <TextInput source="subject" fullWidth validate={required()} />
              <SelectInput
                source="priority"
                choices={PRIORITY_choises}
                validate={required()}
                disabled={isFormDisabled}
              />
              <SelectInput
                source="status"
                choices={STATUS_choises}
                validate={required()}
                disabled={isFormDisabled}
                sx={{ marginLeft: "50px" }}
              />
              <ReferenceInput source="assign_to" reference="tickets/users">
                <SelectInput
                  optionText="fullname"
                  sx={{ marginLeft: "50px" }}
                />
              </ReferenceInput>
            </>
          )}

          <TextInput
            source="message"
            multiline
            fullWidth
            minRows={3}
            helperText={false}
            validate={required()}
            disabled={isFormDisabled}
          />
          <ImageInput source="new_images" label="Screenshots" multiple>
            <ImageField source="src" title="title" />
          </ImageInput>
          <Button
            type="submit"
            variant="contained"
            sx={{ m: 2}}
            disabled={isCreating || isFormDisabled}
          >
            Submit
          </Button>
        </Form>
      </ListItemText>
    </ListItem>
  );
};

const SetFormContext = ({ resetForm }: any) => {
  const form = useFormContext();
  resetForm.current = form.reset;
  return null;
};
