import * as React from "react";
import {
  Create,
} from "react-admin";
import UserEditForm from "./UserEditForm";

const UserCreate = () => (
  <div>
    <Create title={<span>New user</span>}>
       <UserEditForm />
    </Create>
  </div>
);
 

export default UserCreate;
