export const Rs_Status = [
  {
    id: 0,
    name: "Applied",
  },
  {
    id: 6,
    name: "Brand Reviewing",
  } ,
  {
    id: 1,
    name: "Approved",
  },
  {
    id: 2,
    name: "Shipped",
  },
  {
    id: 3,
    name: "Completed",
  },
  {
    id: 4,
    name: "Canceled",
  },
  {
    id: 5,
    name: "Rejected",
  }
];


