import {
  Datagrid,
  DateField,
  List,
  NumberField,
  RaRecord,
  TextField,
  useGetList,
  useRecordContext,
} from "react-admin";

import { hide_checkbox } from "../comComps/comStyles";
import LinkField from "../comComps/LinkField";

const StoreOrders = () => {
  const record = useRecordContext<RaRecord>();
  if (!record) return null;

  const { data: orders } = useGetList<RaRecord>(`orders/store/${record.id}`, {
    sort: { field: "created_at", order: "DESC" },
    pagination: { page: 1, perPage: 1000 },
  });
  if (!orders) return null;

  return (
    <Datagrid isRowSelectable={() => false} sx={hide_checkbox} data={orders}>
      <DateField source="created_at" showTime label="Ordered at" />
      <LinkField
        reference="orders"
        label="Reference"
        keyId="id"
        resource="uuid"
      />
      <TextField source="currency" />
      <NumberField
        source="total"
        options={{
          style: "currency",
          currency: "USD",
        }}
        sx={{ fontWeight: "bold" }}
      />
    </Datagrid>
  );
};

export default StoreOrders;
