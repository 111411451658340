import * as React from "react";
import {
  Create,
} from "react-admin";
import PartnerShipEditForm from "./PartnerShipEditForm";
 
const PartnerShipCreate = () => (
  <div>
    <Create title={<span>New product</span>}>
       <PartnerShipEditForm />
    </Create>
  </div>
);
 

export default PartnerShipCreate;
