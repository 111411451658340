import * as React from "react";
import { Link, FieldProps, useRecordContext, RaRecord, BooleanInput } from "react-admin";

import { memo } from "react";
import { Grid, Typography } from "@mui/material";

export interface Props extends FieldProps<RaRecord> {
  // reference: string;
  // keyId: string;
}
const UserEditRole = (props: Props) => {
  const record = useRecordContext<RaRecord>();
  if (!record) {
    return null;
  }
  return (
    <Grid container >
      <Grid item xs={12} md={4}>
        <Typography gutterBottom sx={{marginTop: '5px'}}>
          {record.role}
        </Typography>
      </Grid>
      <Grid item xs={12} md={4}>
        <BooleanInput
          name={`role_r_${record.id}`}
          source="read"
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <BooleanInput
          name={`role_w_${record.id}`}
          source="write"
        />
      </Grid>
    </Grid>
  );
};

export default memo<Props>(UserEditRole);
