import * as React from "react";
import {
  BooleanInput,
  required,
  SelectInput,
  TextInput,
  useGetList,
} from "react-admin";
import { Grid, Typography } from "@mui/material";
import { useWatch } from "react-hook-form";
import { SaleRegion } from "./sale_region";

export const BrandEditDetails = () => {
 
  const { data: states_ } = useGetList("brands/states", {
    pagination: { page: 1, perPage: 2000 },
  });

  const { data: countries_ } = useGetList("brands/countries", {
    pagination: { page: 1, perPage: 1000 },
  });
  let states = states_? states_: [];
  let countries = countries_? countries_: [];
  let toChoices = (country: any) =>
    {
      let ches = states.filter(item => item['country_id'] == country)
      .reduce(
          (all, item) => {
            all.push(item);
            return all;
          },
          []
      );
      if(!ches || ches.length == 0){
        ches = [{id: 0, name: 'Other'}];
      }
      return ches;
    };

  const StateInput = (props: any) => {
      const country = useWatch({ name: 'country_id' });
      return (
          <SelectInput
              choices={country ? toChoices(country) : []}
              {...props}
          />
      );
  };

  return (
    <Grid container columnSpacing={2}>
      <Grid item xs={12} sm={6}>
        <TextInput source="name" fullWidth validate={req} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextInput source="company_name"  label="Sub brand" fullWidth validate={req} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextInput source="email" fullWidth validate={req} />
      </Grid>
      <Grid item xs={6} sm={4}>
        <BooleanInput source="partner_share" />
      </Grid>
      <Grid item xs={6} sm={2}>
        <BooleanInput source="active" />
      </Grid>
      <Grid item xs={12} sm={6}>
        <SelectInput validate={req} 
          source="team_id" label="Sale Region"
          choices={SaleRegion}
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <Typography variant="h6" gutterBottom>
          {"Address"}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12}>
        <TextInput source="street" fullWidth validate={req} />
      </Grid>
      <Grid item xs={12} sm={12}>
        <TextInput source="street2" fullWidth />
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextInput source="city" fullWidth validate={req} />
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextInput source="zip" fullWidth validate={req} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <SelectInput  validate={req} 
          source="country_id"
          choices={countries}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <StateInput validate={req}
          source="state_id"
          // choices={states }
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextInput source="phone" fullWidth validate={req} />
      </Grid>
     
    </Grid>
  );
};

const req = [required()];
